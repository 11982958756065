/**
 * =============================================================================
 * ************   Fab DATA API   ************
 * =============================================================================
 */

$(function () {
  // mouseenter ��ð�ݣ��޷������¼�ί�У������� mouseover ���档
  // ������ click �� mouseover ���� touchstart �����ȳ�ʼ����

  $document.on('touchstart mousedown mouseover', '[mdui-fab]', function (e) {
    var $this = $(this);

    var inst = $this.data('mdui.fab');
    if (!inst) {
      var options = parseOptions($this.attr('mdui-fab'));
      inst = new mdui.Fab($this, options);
      $this.data('mdui.fab', inst);
    }
  });
});
