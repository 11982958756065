/**
 * =============================================================================
 * ************   Tab   ************
 * =============================================================================
 */

mdui.Tab = (function () {

  var DEFAULT = {
    trigger: 'click',       // ������ʽ click: ������л� hover: ��������л�
    //animation: false,       // �л�ʱ�Ƿ���ʾ����
    loop: false,            // Ϊtrueʱ�������һ��ѡ�ʱ���� next() ������ص���һ��ѡ�
  };

  // Ԫ���Ƿ��ѽ���
  var isDisabled = function ($ele) {
    return $ele[0].disabled || $ele.attr('disabled') !== null;
  };

  /**
   * ѡ�
   * @param selector
   * @param opts
   * @returns {*}
   * @constructor
   */
  function Tab(selector, opts) {
    var _this = this;

    _this.$tab = $(selector).eq(0);
    if (!_this.$tab.length) {
      return;
    }

    // ��ͨ���Զ�������ʵ�������������ظ�ʵ����
    var oldInst = _this.$tab.data('mdui.tab');
    if (oldInst) {
      return oldInst;
    }

    _this.options = $.extend({}, DEFAULT, (opts || {}));
    _this.$tabs = _this.$tab.children('a');
    _this.$indicator = $('<div class="mdui-tab-indicator"></div>').appendTo(_this.$tab);
    _this.activeIndex = false; // Ϊ false ʱ��ʾû�м����ѡ����򲻴���ѡ�

    // ���� url hash ��ȡĬ�ϼ����ѡ�
    var hash = location.hash;
    if (hash) {
      _this.$tabs.each(function (i, tab) {
        if ($(tab).attr('href') === hash) {
          _this.activeIndex = i;
          return false;
        }
      });
    }

    // �� mdui-tab-active ��Ԫ��Ĭ�ϼ���
    if (_this.activeIndex === false) {
      _this.$tabs.each(function (i, tab) {
        if ($(tab).hasClass('mdui-tab-active')) {
          _this.activeIndex = i;
          return false;
        }
      });
    }

    // ����ѡ�ʱ��Ĭ�ϼ����һ��ѡ�
    if (_this.$tabs.length && _this.activeIndex === false) {
      _this.activeIndex = 0;
    }

    // ���ü���״̬ѡ�
    _this._setActive();

    // �������ڴ�С�仯�¼�������ָʾ��λ��
    $window.on('resize', $.throttle(function () {
      _this._setIndicatorPosition();
    }, 100));

    // �������ѡ��¼�
    _this.$tabs.each(function (i, tab) {
      _this._bindTabEvent(tab);
    });
  }

  /**
   * ���� Tab �ϵ�����������¼�
   * @private
   */
  Tab.prototype._bindTabEvent = function (tab) {
    var _this = this;
    var $tab = $(tab);

    // �����������봥�����¼�
    var clickEvent = function (e) {
      // ����״̬��ѡ���޷�ѡ��
      if (isDisabled($tab)) {
        e.preventDefault();
        return;
      }

      _this.activeIndex = _this.$tabs.index(tab);
      _this._setActive();
    };

    // ���� trigger �� click ���� hover��������Ӧ click �¼�
    $tab.on('click', clickEvent);

    // trigger Ϊ hover ʱ��������Ӧ mouseenter �¼�
    if (_this.options.trigger === 'hover') {
      $tab.on('mouseenter', clickEvent);
    }

    $tab.on('click', function (e) {
      // ��ֹ���ӵ�Ĭ�ϵ������
      if ($tab.attr('href').indexOf('#') === 0) {
        e.preventDefault();
      }
    });
  };

  /**
   * ���ü���״̬��ѡ�
   * @private
   */
  Tab.prototype._setActive = function () {
    var _this = this;

    _this.$tabs.each(function (i, tab) {
      var $tab = $(tab);
      var targetId = $tab.attr('href');

      // ����ѡ�����״̬
      if (i === _this.activeIndex && !isDisabled($tab)) {
        if (!$tab.hasClass('mdui-tab-active')) {
          componentEvent('change', 'tab', _this, _this.$tab, {
            index: _this.activeIndex,
            id: targetId.substr(1),
          });
          componentEvent('show', 'tab', _this, $tab);

          $tab.addClass('mdui-tab-active');
        }

        $(targetId).show();
        _this._setIndicatorPosition();
      } else {
        $tab.removeClass('mdui-tab-active');
        $(targetId).hide();
      }
    });
  };

  /**
   * ����ѡ�ָʾ����λ��
   */
  Tab.prototype._setIndicatorPosition = function () {
    var _this = this;
    var $activeTab;
    var activeTabOffset;

    // ѡ�����Ϊ 0 ʱ������ʾָʾ��
    if (_this.activeIndex === false) {
      _this.$indicator.css({
        left: 0,
        width: 0,
      });

      return;
    }

    $activeTab = _this.$tabs.eq(_this.activeIndex);
    if (isDisabled($activeTab)) {
      return;
    }

    activeTabOffset = $activeTab.offset();
    _this.$indicator.css({
      left: activeTabOffset.left + _this.$tab[0].scrollLeft -
            _this.$tab[0].getBoundingClientRect().left + 'px',
      width: $activeTab.width() + 'px',
    });
  };

  /**
   * �л�����һ��ѡ�
   */
  Tab.prototype.next = function () {
    var _this = this;

    if (_this.activeIndex === false) {
      return;
    }

    if (_this.$tabs.length > _this.activeIndex + 1) {
      _this.activeIndex++;
    } else if (_this.options.loop) {
      _this.activeIndex = 0;
    }

    _this._setActive();
  };

  /**
   * �л�����һ��ѡ�
   */
  Tab.prototype.prev = function () {
    var _this = this;

    if (_this.activeIndex === false) {
      return;
    }

    if (_this.activeIndex > 0) {
      _this.activeIndex--;
    } else if (_this.options.loop) {
      _this.activeIndex = _this.$tabs.length - 1;
    }

    _this._setActive();
  };

  /**
   * ��ʾָ����Ż�ָ��id��ѡ�
   * @param index ��0��ʼ����ţ�����#��ͷ��id
   */
  Tab.prototype.show = function (index) {
    var _this = this;

    if (_this.activeIndex === false) {
      return;
    }

    if (parseInt(index) === index) {
      _this.activeIndex = index;
    } else {
      _this.$tabs.each(function (i, tab) {
        if (tab.id === index) {
          _this.activeIndex = i;
          return false;
        }
      });
    }

    _this._setActive();
  };

  /**
   * �ڸ�Ԫ�صĿ��ȱ仯ʱ����Ҫ���ø÷������µ���ָʾ��λ��
   * �����ӻ�ɾ��ѡ�ʱ����Ҫ���ø÷���
   */
  Tab.prototype.handleUpdate = function () {
    var _this = this;

    var $oldTabs = _this.$tabs;               // �ɵ� tabs JQ����
    var $newTabs = _this.$tab.children('a');  // �µ� tabs JQ����
    var oldTabsEle = $oldTabs.get();          // �� tabs ��Ԫ������
    var newTabsEle = $newTabs.get();          // �µ� tabs Ԫ������

    if (!$newTabs.length) {
      _this.activeIndex = false;
      _this.$tabs = $newTabs;
      _this._setIndicatorPosition();

      return;
    }

    // ���±���ѡ����ҳ�������ѡ�
    $newTabs.each(function (i, tab) {
      // ��������ѡ�
      if (oldTabsEle.indexOf(tab) < 0) {
        _this._bindTabEvent(tab);

        if (_this.activeIndex === false) {
          _this.activeIndex = 0;
        } else if (i <= _this.activeIndex) {
          _this.activeIndex++;
        }
      }
    });

    // �ҳ����Ƴ���ѡ�
    $oldTabs.each(function (i, tab) {
      // �б��Ƴ���ѡ�
      if (newTabsEle.indexOf(tab) < 0) {

        if (i < _this.activeIndex) {
          _this.activeIndex--;
        } else if (i === _this.activeIndex) {
          _this.activeIndex = 0;
        }
      }
    });

    _this.$tabs = $newTabs;

    _this._setActive();
  };

  return Tab;
})();
