/**
 * =============================================================================
 * ************   Select ����ѡ��   ************
 * =============================================================================
 */

mdui.Select = (function () {

  /**
   * Ĭ�ϲ���
   */
  var DEFAULT = {
    position: 'auto',                // ������λ�ã�auto��bottom��top
    gutter: 16,                      // �˵��봰�����±߿����ٱ��ֶ��ټ��
  };

  /**
   * �����˵�λ��
   * @param _this Select ʵ��
   */
  var readjustMenu = function (_this) {
    // ���ڸ߶�
    var windowHeight = $window.height();

    // ���ò���
    var gutter = _this.options.gutter;
    var position = _this.options.position;

    // mdui-select �߶�
    var selectHeight = parseInt(_this.$select.height());

    // �˵���߶�
    var $menuItemFirst = _this.$items.eq(0);
    var menuItemHeight = parseInt($menuItemFirst.height());
    var menuItemMargin = parseInt($menuItemFirst.css('margin-top'));

    // �˵��߶�
    var menuWidth = parseFloat(_this.$select.width() + 0.01); // �������ʵ���ȶ�һ�㣬��Ȼ�����ʡ�Ժ�
    var menuHeight = menuItemHeight * _this.size + menuItemMargin * 2;

    // var menuRealHeight = menuItemHeight * _this.$items.length + menuItemMargin * 2;

    // �˵��Ƿ�����˹�����
    //var isMenuScrollable = menuRealHeight > menuHeight;

    // select �ڴ����е�λ��
    var selectTop = _this.$select[0].getBoundingClientRect().top;

    var transformOriginY;
    var menuMarginTop;

    // position Ϊ auto ʱ
    if (position === 'auto') {

      // �˵��߶Ȳ��ܳ������ڸ߶�
      var heightTemp = windowHeight - gutter * 2;
      if (menuHeight > heightTemp) {
        menuHeight = heightTemp;
      }

      // �˵��� margin-top
      menuMarginTop = -(
        menuItemMargin + _this.selectedIndex * menuItemHeight +
        (menuItemHeight - selectHeight) / 2
      );
      var menuMarginTopMax = -(
        menuItemMargin + (_this.size - 1) * menuItemHeight +
        (menuItemHeight - selectHeight) / 2
      );
      if (menuMarginTop < menuMarginTopMax) {
        menuMarginTop = menuMarginTopMax;
      }

      // �˵����ܳ�������
      var menuTop = selectTop + menuMarginTop;

      if (menuTop < gutter) {
        // ���ܳ��������Ϸ�
        menuMarginTop = -(selectTop - gutter);
      } else if (menuTop + menuHeight + gutter > windowHeight) {
        // ���ܳ��������·�
        menuMarginTop = -(selectTop + menuHeight + gutter - windowHeight);
      }

      // transform �� Y ������
      transformOriginY = (_this.selectedIndex * menuItemHeight + menuItemHeight / 2 + menuItemMargin) + 'px';
    } else if (position === 'bottom') {
      menuMarginTop = selectHeight;
      transformOriginY = '0px';
    } else if (position === 'top') {
      menuMarginTop = -menuHeight - 1;
      transformOriginY = '100%';
    }

    // ������ʽ
    _this.$select.width(menuWidth);
    _this.$menu
      .width(menuWidth)
      .height(menuHeight)
      .css({
        'margin-top': menuMarginTop + 'px',
        'transform-origin':
        'center ' + transformOriginY + ' 0',
      });
  };

  /**
   * ����ѡ��
   * @param selector
   * @param opts
   * @constructor
   */
  function Select(selector, opts) {
    var _this = this;

    var $selectNative =  _this.$selectNative = $(selector).eq(0);
    if (!$selectNative.length) {
      return;
    }

    // ��ͨ���Զ�������ʵ�������������ظ�ʵ����
    var oldInst = $selectNative.data('mdui.select');
    if (oldInst) {
      return oldInst;
    }

    $selectNative.hide();

    _this.options = $.extend({}, DEFAULT, (opts || {}));

    // Ϊ��ǰ select ����Ψһ ID
    _this.uniqueID = $.guid();

    _this.state = 'closed';

    // ���� select
    _this.handleUpdate();

    // ��� select ��������ر�
    $document.on('click touchstart', function (e) {
      var $target = $(e.target);
      if (
        (_this.state === 'opening' || _this.state === 'opened') &&
        !$target.is(_this.$select) &&
        !$.contains(_this.$select[0], $target[0])
      ) {
        _this.close();
      }
    });
  }

  /**
   * ��ԭ�� select ����������޸ĺ���Ҫ���ø÷���
   */
  Select.prototype.handleUpdate = function () {
    var _this = this;

    if (_this.state === 'opening' || _this.state === 'opened') {
      _this.close();
    }

    var $selectNative = _this.$selectNative;

    // ��ǰ��ֵ���ı�
    _this.value = $selectNative.val();
    _this.text = '';

    // ���� HTML
    // �˵���
    _this.$items = $();
    $selectNative.find('option').each(function (index, option) {
      var data = {
        value: option.value,
        text: option.textContent,
        disabled: option.disabled,
        selected: _this.value === option.value,
        index: index,
      };

      if (_this.value === data.value) {
        _this.text = data.text;
        _this.selectedIndex = index;
      }

      _this.$items = _this.$items.add(
        $('<div class="mdui-select-menu-item mdui-ripple"' +
          (data.disabled ? ' disabled' : '') +
          (data.selected ? ' selected' : '') + '>' + data.text + '</div>')
          .data(data)
      );
    });

    // selected
    _this.$selected = $('<span class="mdui-select-selected">' + _this.text + '</span>');

    // select
    _this.$select =
      $(
        '<div class="mdui-select mdui-select-position-' + _this.options.position + '" ' +
        'style="' + _this.$selectNative.attr('style') + '" ' +
        'id="' + _this.uniqueID + '"></div>'
      )
        .show()
        .append(_this.$selected);

    // menu
    _this.$menu =
      $('<div class="mdui-select-menu"></div>')
        .appendTo(_this.$select)
        .append(_this.$items);

    $('#' + _this.uniqueID).remove();
    $selectNative.after(_this.$select);

    // ���� select �� size �������ø߶ȣ�Ĭ��Ϊ 6
    _this.size = parseInt(_this.$selectNative.attr('size'));

    if (!_this.size || _this.size < 0) {
      _this.size = _this.$items.length;
      if (_this.size > 8) {
        _this.size = 8;
      }
    }

    // ���ѡ��ʱ�ر������˵�
    _this.$items.on('click', function () {
      if (_this.state === 'closing') {
        return;
      }

      var $item = $(this);

      if ($item.data('disabled')) {
        return;
      }

      var itemData = $item.data();

      _this.$selected.text(itemData.text);
      $selectNative.val(itemData.value);
      _this.$items.removeAttr('selected');
      $item.attr('selected', '');
      _this.selectedIndex = itemData.index;
      _this.value = itemData.value;
      _this.text = itemData.text;
      $selectNative.trigger('change');

      _this.close();
    });

    // ��� select ʱ�������˵�
    _this.$select.on('click', function (e) {
      var $target = $(e.target);

      // �ڲ˵��ϵ��ʱ����
      if ($target.is('.mdui-select-menu') || $target.is('.mdui-select-menu-item')) {
        return;
      }

      _this.toggle();
    });
  };

  /**
   * ���������ص�
   * @param inst
   */
  var transitionEnd = function (inst) {
    inst.$select.removeClass('mdui-select-closing');

    if (inst.state === 'opening') {
      inst.state = 'opened';
      componentEvent('opened', 'select', inst, inst.$selectNative);

      inst.$menu.css('overflow-y', 'auto');
    }

    if (inst.state === 'closing') {
      inst.state = 'closed';
      componentEvent('closed', 'select', inst, inst.$selectNative);

      // �ָ���ʽ
      inst.$select.width('');
      inst.$menu.css({
        'margin-top': '',
        height: '',
        width: '',
      });
    }
  };

  /**
   * �� Select
   */
  Select.prototype.open = function () {
    var _this = this;

    if (_this.state === 'opening' || _this.state === 'opened') {
      return;
    }

    _this.state = 'opening';
    componentEvent('open', 'select', _this, _this.$selectNative);

    readjustMenu(_this);

    _this.$select.addClass('mdui-select-open');

    _this.$menu.transitionEnd(function () {
      transitionEnd(_this);
    });
  };

  /**
   * �ر� Select
   */
  Select.prototype.close = function () {
    var _this = this;

    if (_this.state === 'closing' || _this.state === 'closed') {
      return;
    }

    _this.state = 'closing';
    componentEvent('close', 'select', _this, _this.$selectNative);

    _this.$menu.css('overflow-y', '');

    _this.$select
      .removeClass('mdui-select-open')
      .addClass('mdui-select-closing');
    _this.$menu.transitionEnd(function () {
      transitionEnd(_this);
    });
  };

  /**
   * �л� Select ��ʾ״̬
   */
  Select.prototype.toggle = function () {
    var _this = this;

    if (_this.state === 'opening' || _this.state === 'opened') {
      _this.close();
    } else if (_this.state === 'closing' || _this.state === 'closed') {
      _this.open();
    }
  };

  return Select;
})();
