/**
 * =============================================================================
 * ************   Menu �˵�   ************
 * =============================================================================
 */

mdui.Menu = (function () {

  /**
   * Ĭ�ϲ���
   */
  var DEFAULT = {
    position: 'auto',         // �˵�λ�� top��bottom��center��auto
    align: 'auto',            // �˵��ʹ�������Ԫ�صĶ��뷽ʽ left��right��center��auto
    gutter: 16,               // �˵����봰�ڱ�Ե����С���룬��λ px
    fixed: false,             // �Ƿ�ʹ�˵��̶��ڴ��ڣ��������������
    covered: 'auto',          // �˵��Ƿ񸲸��ڴ�������Ԫ���ϣ�true��false��auto ʱ�򵥲˵����ǣ������˵�������
    subMenuTrigger: 'hover',  // �Ӳ˵��Ĵ�����ʽ hover��click
    subMenuDelay: 200,        // �Ӳ˵��Ĵ�����ʱ������ submenuTrigger Ϊ hover ��Ч
  };

  /**
   * �������˵�λ��
   * @param _this ʵ��
   */
  var readjust = function (_this) {
    var menuLeft;
    var menuTop;

    // �˵�λ�úͷ���
    var position;
    var align;

    // window ���ڵĿ��Ⱥ͸߶�
    var windowHeight = $window.height();
    var windowWidth = $window.width();

    // ���ò���
    var gutter = _this.options.gutter;
    var isCovered = _this.isCovered;
    var isFixed = _this.options.fixed;

    // �����������
    var transformOriginX;
    var transformOriginY;

    // �˵���ԭʼ���Ⱥ͸߶�
    var menuWidth = _this.$menu.width();
    var menuHeight = _this.$menu.height();

    var $anchor = _this.$anchor;

    // �����˵���Ԫ���ڴ����е�λ��
    var anchorTmp = $anchor[0].getBoundingClientRect();
    var anchorTop = anchorTmp.top;
    var anchorLeft = anchorTmp.left;
    var anchorHeight = anchorTmp.height;
    var anchorWidth = anchorTmp.width;
    var anchorBottom = windowHeight - anchorTop - anchorHeight;
    var anchorRight = windowWidth - anchorLeft - anchorWidth;

    // ����Ԫ�������ӵ�ж�λ���Եĸ�Ԫ�ص�λ��
    var anchorOffsetTop = $anchor[0].offsetTop;
    var anchorOffsetLeft = $anchor[0].offsetLeft;

    // ===============================
    // ================= �Զ��жϲ˵�λ��
    // ===============================
    if (_this.options.position === 'auto') {

      // �ж��·��Ƿ�ŵ��²˵�
      if (anchorBottom + (isCovered ? anchorHeight : 0) > menuHeight + gutter) {
        position = 'bottom';
      }

      // �ж��Ϸ��Ƿ�ŵ��²˵�
      else if (anchorTop + (isCovered ? anchorHeight : 0) > menuHeight + gutter) {
        position = 'top';
      }

      // ���¶��Ų��£�������ʾ
      else {
        position = 'center';
      }
    } else {
      position = _this.options.position;
    }

    // ===============================
    // ============== �Զ��жϲ˵����뷽ʽ
    // ===============================
    if (_this.options.align === 'auto') {

      // �ж��Ҳ��Ƿ�ŵ��²˵�
      if (anchorRight + anchorWidth > menuWidth + gutter) {
        align = 'left';
      }

      // �ж�����Ƿ�ŵ��²˵�
      else if (anchorLeft + anchorWidth > menuWidth + gutter) {
        align = 'right';
      }

      // ���Ҷ��Ų��£�������ʾ
      else {
        align = 'center';
      }
    } else {
      align = _this.options.align;
    }

    // ===============================
    // ==================== ���ò˵�λ��
    // ===============================
    if (position === 'bottom') {
      transformOriginY = '0';

      menuTop =
        (isCovered ? 0 : anchorHeight) +
        (isFixed ? anchorTop : anchorOffsetTop);

    } else if (position === 'top') {
      transformOriginY = '100%';

      menuTop =
        (isCovered ? anchorHeight : 0) +
        (isFixed ? (anchorTop - menuHeight) : (anchorOffsetTop - menuHeight));

    } else {
      transformOriginY = '50%';

      // =====================�ڴ����о���
      // ��ʾ�Ĳ˵��ĸ߶ȣ��򵥲˵��߶Ȳ��������ڸ߶ȣ������������ڲ˵��ڲ���ʾ������
      // �����˵��ڲ����������ֹ�����
      var menuHeightTemp = menuHeight;

      // �򵥲˵��ȴ��ڸ�ʱ�����Ʋ˵��߶�
      if (!_this.isCascade) {
        if (menuHeight + gutter * 2 > windowHeight) {
          menuHeightTemp = windowHeight - gutter * 2;
          _this.$menu.height(menuHeightTemp);
        }
      }

      menuTop =
        (windowHeight - menuHeightTemp) / 2 +
        (isFixed ? 0 : (anchorOffsetTop - anchorTop));
    }

    _this.$menu.css('top', menuTop + 'px');

    // ===============================
    // ================= ���ò˵����뷽ʽ
    // ===============================
    if (align === 'left') {
      transformOriginX = '0';

      menuLeft = isFixed ? anchorLeft : anchorOffsetLeft;

    } else if (align === 'right') {
      transformOriginX = '100%';

      menuLeft = isFixed ?
        (anchorLeft + anchorWidth - menuWidth) :
        (anchorOffsetLeft + anchorWidth - menuWidth);
    } else {
      transformOriginX = '50%';

      //=======================�ڴ����о���
      // ��ʾ�Ĳ˵��Ŀ��ȣ��˵����Ȳ��ܳ������ڿ���
      var menuWidthTemp = menuWidth;

      // �˵��ȴ��ڿ������Ʋ˵�����
      if (menuWidth + gutter * 2 > windowWidth) {
        menuWidthTemp = windowWidth - gutter * 2;
        _this.$menu.width(menuWidthTemp);
      }

      menuLeft =
        (windowWidth - menuWidthTemp) / 2 +
        (isFixed ? 0 : anchorOffsetLeft - anchorLeft);
    }

    _this.$menu.css('left', menuLeft + 'px');

    // ���ò˵���������
    _this.$menu.transformOrigin(transformOriginX + ' ' + transformOriginY);
  };

  /**
   * �����Ӳ˵���λ��
   * @param $submenu
   */
  var readjustSubmenu = function ($submenu) {
    var $item = $submenu.parent('.mdui-menu-item');

    var submenuTop;
    var submenuLeft;

    // �Ӳ˵�λ�úͷ���
    var position; // top��bottom
    var align; // left��right

    // window ���ڵĿ��Ⱥ͸߶�
    var windowHeight = $window.height();
    var windowWidth = $window.width();

    // �����������
    var transformOriginX;
    var transformOriginY;

    // �Ӳ˵���ԭʼ���Ⱥ͸߶�
    var submenuWidth = $submenu.width();
    var submenuHeight = $submenu.height();

    // �����Ӳ˵��Ĳ˵���Ŀ��ȸ߶�
    var itemTmp = $item[0].getBoundingClientRect();
    var itemWidth = itemTmp.width;
    var itemHeight = itemTmp.height;
    var itemLeft = itemTmp.left;
    var itemTop = itemTmp.top;

    // ===================================
    // ===================== �жϲ˵�����λ��
    // ===================================

    // �ж��·��Ƿ�ŵ��²˵�
    if (windowHeight - itemTop > submenuHeight) {
      position = 'bottom';
    }

    // �ж��Ϸ��Ƿ�ŵ��²˵�
    else if (itemTop + itemHeight > submenuHeight) {
      position = 'top';
    }

    // Ĭ�Ϸ����·�
    else {
      position = 'bottom';
    }

    // ====================================
    // ====================== �жϲ˵�����λ��
    // ====================================

    // �ж��Ҳ��Ƿ�ŵ��²˵�
    if (windowWidth - itemLeft - itemWidth > submenuWidth) {
      align = 'left';
    }

    // �ж�����Ƿ�ŵ��²˵�
    else if (itemLeft > submenuWidth) {
      align = 'right';
    }

    // Ĭ�Ϸ����Ҳ�
    else {
      align = 'left';
    }

    // ===================================
    // ======================== ���ò˵�λ��
    // ===================================
    if (position === 'bottom') {
      transformOriginY = '0';
      submenuTop = '0';
    } else if (position === 'top') {
      transformOriginY = '100%';
      submenuTop = -submenuHeight + itemHeight;
    }

    $submenu.css('top', submenuTop + 'px');

    // ===================================
    // ===================== ���ò˵����뷽ʽ
    // ===================================
    if (align === 'left') {
      transformOriginX = '0';
      submenuLeft = itemWidth;
    } else if (align === 'right') {
      transformOriginX = '100%';
      submenuLeft = -submenuWidth;
    }

    $submenu.css('left', submenuLeft + 'px');

    // ���ò˵���������
    $submenu.transformOrigin(transformOriginX + ' ' + transformOriginY);
  };

  /**
   * ���Ӳ˵�
   * @param $submenu
   */
  var openSubMenu = function ($submenu) {
    readjustSubmenu($submenu);

    $submenu
      .addClass('mdui-menu-open')
      .parent('.mdui-menu-item')
      .addClass('mdui-menu-item-active');
  };

  /**
   * �ر��Ӳ˵�������Ƕ�׵��Ӳ˵�
   * @param $submenu
   */
  var closeSubMenu = function ($submenu) {
    // �ر��Ӳ˵�
    $submenu
      .removeClass('mdui-menu-open')
      .addClass('mdui-menu-closing')
      .transitionEnd(function () {
        $submenu.removeClass('mdui-menu-closing');
      })

      // �Ƴ�����״̬����ʽ
      .parent('.mdui-menu-item')
      .removeClass('mdui-menu-item-active');

    // ѭ���ر�Ƕ�׵��Ӳ˵�
    $submenu.find('.mdui-menu').each(function () {
      var $subSubmenu = $(this);
      $subSubmenu
        .removeClass('mdui-menu-open')
        .addClass('mdui-menu-closing')
        .transitionEnd(function () {
          $subSubmenu.removeClass('mdui-menu-closing');
        })
        .parent('.mdui-menu-item')
        .removeClass('mdui-menu-item-active');
    });
  };

  /**
   * �л��Ӳ˵�״̬
   * @param $submenu
   */
  var toggleSubMenu = function ($submenu) {
    if ($submenu.hasClass('mdui-menu-open')) {
      closeSubMenu($submenu);
    } else {
      openSubMenu($submenu);
    }
  };

  /**
   * ���Ӳ˵��¼�
   * @param inst ʵ��
   */
  var bindSubMenuEvent = function (inst) {
    // ������Ӳ˵�
    inst.$menu.on('click', '.mdui-menu-item', function (e) {
      var $this = $(this);
      var $target = $(e.target);

      // ����״̬�˵�������
      if ($this.attr('disabled') !== null) {
        return;
      }

      // û�е�����Ӳ˵��Ĳ˵�����ʱ�����������������Ӳ˵��Ŀհ����򡢻�ָ����ϣ�
      if ($target.is('.mdui-menu') || $target.is('.mdui-divider')) {
        return;
      }

      // ��ֹð�ݣ�����˵���ʱֻ�����һ���� mdui-menu-item ����Ч��������ð��
      if (!$target.parents('.mdui-menu-item').eq(0).is($this)) {
        return;
      }

      // ��ǰ�˵����Ӳ˵�
      var $submenu = $this.children('.mdui-menu');

      // �ȹرճ���ǰ�Ӳ˵��������ͬ���Ӳ˵�
      $this.parent('.mdui-menu').children('.mdui-menu-item').each(function () {
        var $tmpSubmenu = $(this).children('.mdui-menu');
        if (
          $tmpSubmenu.length &&
          (!$submenu.length || !$tmpSubmenu.is($submenu))
        ) {
          closeSubMenu($tmpSubmenu);
        }
      });

      // �л���ǰ�Ӳ˵�
      if ($submenu.length) {
        toggleSubMenu($submenu);
      }
    });

    if (inst.options.subMenuTrigger === 'hover') {
      // ��ʱ�洢 setTimeout ����
      var timeout;

      var timeoutOpen;
      var timeoutClose;

      inst.$menu.on('mouseover mouseout', '.mdui-menu-item', function (e) {
        var $this = $(this);
        var eventType = e.type;
        var $relatedTarget = $(e.relatedTarget);

        // ����״̬�Ĳ˵�������
        if ($this.attr('disabled') !== null) {
          return;
        }

        // �� mouseover ģ�� mouseenter
        if (eventType === 'mouseover') {
          if (!$this.is($relatedTarget) && $.contains($this[0], $relatedTarget[0])) {
            return;
          }
        }

        // �� mouseout ģ�� mouseleave
        else if (eventType === 'mouseout') {
          if ($this.is($relatedTarget) || $.contains($this[0], $relatedTarget[0])) {
            return;
          }
        }

        // ��ǰ�˵����µ��Ӳ˵���δ�ش���
        var $submenu = $this.children('.mdui-menu');

        // �������˵���ʱ����ʾ�˵����µ��Ӳ˵�
        if (eventType === 'mouseover') {
          if ($submenu.length) {

            // ��ǰ�Ӳ˵�׼����ʱ�������ǰ�Ӳ˵���׼���Źرգ������ٹر���
            var tmpClose = $submenu.data('timeoutClose.mdui.menu');
            if (tmpClose) {
              clearTimeout(tmpClose);
            }

            // �����ǰ�Ӳ˵��Ѿ��򿪣�������
            if ($submenu.hasClass('mdui-menu-open')) {
              return;
            }

            // ��ǰ�Ӳ˵�׼����ʱ������׼���򿪵��Ӳ˵������ٴ���
            clearTimeout(timeoutOpen);

            // ׼���򿪵�ǰ�Ӳ˵�
            timeout = timeoutOpen = setTimeout(function () {
              openSubMenu($submenu);
            }, inst.options.subMenuDelay);

            $submenu.data('timeoutOpen.mdui.menu', timeout);
          }
        }

        // ����Ƴ��˵���ʱ���رղ˵����µ��Ӳ˵�
        else if (eventType === 'mouseout') {
          if ($submenu.length) {

            // ����Ƴ��˵���ʱ�������ǰ�˵����µ��Ӳ˵���׼���򿪣������ٴ���
            var tmpOpen = $submenu.data('timeoutOpen.mdui.menu');
            if (tmpOpen) {
              clearTimeout(tmpOpen);
            }

            // ׼���رյ�ǰ�Ӳ˵�
            timeout = timeoutClose = setTimeout(function () {
              closeSubMenu($submenu);
            }, inst.options.subMenuDelay);

            $submenu.data('timeoutClose.mdui.menu', timeout);
          }
        }
      });
    }
  };

  /**
   * �˵�
   * @param anchorSelector �����Ԫ�ش����˵�
   * @param menuSelector �˵�
   * @param opts ������
   * @constructor
   */
  function Menu(anchorSelector, menuSelector, opts) {
    var _this = this;

    // �����˵���Ԫ��
    _this.$anchor = $(anchorSelector).eq(0);
    if (!_this.$anchor.length) {
      return;
    }

    // ��ͨ���Զ�������ʵ�������������ظ�ʵ����
    var oldInst = _this.$anchor.data('mdui.menu');
    if (oldInst) {
      return oldInst;
    }

    _this.$menu = $(menuSelector).eq(0);

    // �����˵���Ԫ�� �� �˵�������ͬ����Ԫ�أ�����˵����ܲ��ܶ�λ
    if (!_this.$anchor.siblings(_this.$menu).length) {
      return;
    }

    _this.options = $.extend({}, DEFAULT, (opts || {}));
    _this.state = 'closed';

    // �Ƿ��Ǽ����˵�
    _this.isCascade = _this.$menu.hasClass('mdui-menu-cascade');

    // covered ��������
    if (_this.options.covered === 'auto') {
      _this.isCovered = !_this.isCascade;
    } else {
      _this.isCovered = _this.options.covered;
    }

    // ��������˵��л�
    _this.$anchor.on('click', function () {
      _this.toggle();
    });

    // ����˵���������رղ˵�
    $document.on('click touchstart', function (e) {
      var $target = $(e.target);
      if (
        (_this.state === 'opening' || _this.state === 'opened') &&
          !$target.is(_this.$menu) &&
          !$.contains(_this.$menu[0], $target[0]) &&
          !$target.is(_this.$anchor) &&
          !$.contains(_this.$anchor[0], $target[0])
      ) {
        _this.close();
      }
    });

    // ��������Ӳ˵��Ĳ˵���Ŀ�رղ˵�
    $document.on('click', '.mdui-menu-item', function (e) {
      var $this = $(this);
      if (!$this.find('.mdui-menu').length && $this.attr('disabled') === null) {
        _this.close();
      }
    });

    // �󶨵����������뺬�Ӳ˵�����Ŀ���¼�
    bindSubMenuEvent(_this);

    // ���ڴ�С�仯ʱ�����µ����˵�λ��
    $window.on('resize', $.throttle(function () {
      readjust(_this);
    }, 100));
  }

  /**
   * �л��˵�״̬
   */
  Menu.prototype.toggle = function () {
    var _this = this;

    if (_this.state === 'opening' || _this.state === 'opened') {
      _this.close();
    } else if (_this.state === 'closing' || _this.state === 'closed') {
      _this.open();
    }
  };

  /**
   * ���������ص�
   * @param inst
   */
  var transitionEnd = function (inst) {
    inst.$menu.removeClass('mdui-menu-closing');

    if (inst.state === 'opening') {
      inst.state = 'opened';
      componentEvent('opened', 'menu', inst, inst.$menu);
    }

    if (inst.state === 'closing') {
      inst.state = 'closed';
      componentEvent('closed', 'menu', inst, inst.$menu);

      // �رպ󣬻ָ��˵���ʽ��Ĭ��״̬�����ָ� fixed ��λ
      inst.$menu.css({
        top: '',
        left: '',
        width: '',
        position: 'fixed',
      });
    }
  };

  /**
   * �򿪲˵�
   */
  Menu.prototype.open = function () {
    var _this = this;

    if (_this.state === 'opening' || _this.state === 'opened') {
      return;
    }

    _this.state = 'opening';
    componentEvent('open', 'menu', _this, _this.$menu);

    // �����˵�λ��
    readjust(_this);

    _this.$menu

      // �˵�����״̬ʹ��ʹ�� fixed ��λ��
      .css('position', _this.options.fixed ? 'fixed' : 'absolute')

      // �򿪲˵�
      .addClass('mdui-menu-open')

      // �򿪶�����ɺ�
      .transitionEnd(function () {
        transitionEnd(_this);
      });
  };

  /**
   * �رղ˵�
   */
  Menu.prototype.close = function () {
    var _this = this;
    if (_this.state === 'closing' || _this.state === 'closed') {
      return;
    }

    _this.state = 'closing';
    componentEvent('close', 'menu', _this, _this.$menu);

    // �˵���ʼ�ر�ʱ���ر������Ӳ˵�
    _this.$menu.find('.mdui-menu').each(function () {
      closeSubMenu($(this));
    });

    _this.$menu
      .removeClass('mdui-menu-open')
      .addClass('mdui-menu-closing')
      .transitionEnd(function () {
        transitionEnd(_this);
      });
  };

  return Menu;
})();
