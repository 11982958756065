/**
 * =============================================================================
 * ************   ����ȫ�ֱ���   ************
 * =============================================================================
 */

var $document = $(document);
var $window = $(window);

/**
 * ���� -- ��ǰ���е� api �� jquery ��һ�������Բ������ mdui.JQ ��
 */
var queue = {};
(function () {
  var queueData = [];

  /**
   * д�����
   * @param queueName ������
   * @param func ���������ò���Ϊ��ʱ���������ж���
   */
  queue.queue = function (queueName, func) {
    if (queueData[queueName] === undefined) {
      queueData[queueName] = [];
    }

    if (func === undefined) {
      return queueData[queueName];
    }

    queueData[queueName].push(func);
  };

  /**
   * �Ӷ������Ƴ���һ����������ʵ�иú���
   * @param queueName
   */
  queue.dequeue = function (queueName) {
    if (queueData[queueName] !== undefined && queueData[queueName].length) {
      (queueData[queueName].shift())();
    }
  };

})();

/**
 * touch �¼���� 500ms �ڽ��� mousedown �¼�
 *
 * ��֧�Ŵ��ص���Ļ���¼�˳��Ϊ mousedown -> mouseup -> click
 * ֧�Ŵ��ص���Ļ���¼�˳��Ϊ touchstart -> touchend -> mousedown -> mouseup -> click
 */
var TouchHandler = {
  touches: 0,

  /**
   * ���¼��Ƿ�����
   * ��ʵ���¼�ǰ���ø÷����ж��¼��Ƿ����ʵ��
   * @param e
   * @returns {boolean}
   */
  isAllow: function (e) {
    var allow = true;

    if (
      TouchHandler.touches &&
      [
        'mousedown',
        'mouseup',
        'mousemove',
        'click',
        'mouseover',
        'mouseout',
        'mouseenter',
        'mouseleave',
      ].indexOf(e.type) > -1
    ) {
      // ������ touch �¼�����ֹ����¼�
      allow = false;
    }

    return allow;
  },

  /**
   * �� touchstart �� touchmove��touchend��touchcancel �¼��е��ø÷���ע���¼�
   * @param e
   */
  register: function (e) {
    if (e.type === 'touchstart') {
      // ������ touch �¼�
      TouchHandler.touches += 1;
    } else if (['touchmove', 'touchend', 'touchcancel'].indexOf(e.type) > -1) {
      // touch �¼����� 500ms ����������¼�����ֹ
      setTimeout(function () {
        if (TouchHandler.touches) {
          TouchHandler.touches -= 1;
        }
      }, 500);
    }
  },

  start: 'touchstart mousedown',
  move: 'touchmove mousemove',
  end: 'touchend mouseup',
  cancel: 'touchcancel mouseleave',
  unlock: 'touchend touchmove touchcancel',
};

// �����¼�
// ��ÿһ���¼��ж�ʹ�� TouchHandler.isAllow(e) �ж��¼��Ƿ��ʵ��
// �� touchstart �� touchmove��touchend��touchcancel
// (function () {
//
//   $document
//     .on(TouchHandler.start, function (e) {
//       if (!TouchHandler.isAllow(e)) {
//         return;
//       }
//       TouchHandler.register(e);
//       console.log(e.type);
//     })
//     .on(TouchHandler.move, function (e) {
//       if (!TouchHandler.isAllow(e)) {
//         return;
//       }
//       console.log(e.type);
//     })
//     .on(TouchHandler.end, function (e) {
//       if (!TouchHandler.isAllow(e)) {
//         return;
//       }
//       console.log(e.type);
//     })
//     .on(TouchHandler.unlock, TouchHandler.register);
// })();

$(function () {
  // ����ҳ��������ֱ��ʵ��css����
  // https://css-tricks.com/transitions-only-after-page-load/

  setTimeout(function () {
    $('body').addClass('mdui-loaded');
  }, 0);
});
