/**
 * JQ 1.0.0 (https://github.com/zdhxiong/mdui.JQ#readme)
 * Copyright 2018-2018 zdhxiong
 * Licensed under MIT
 */
var $ = (function () {
  'use strict';

  var JQ = function JQ(arr) {
    var self = this;

    for (var i = 0; i < arr.length; i += 1) {
      self[i] = arr[i];
    }

    self.length = arr.length;

    return this;
  };

  function $(selector) {
    var arr = [];

    if (!selector) {
      return new JQ(arr);
    }

    if (selector instanceof JQ) {
      return selector;
    }

    if (typeof selector === 'string') {
      var html = selector.trim();

      if (html[0] === '<' && html[html.length - 1] === '>') {
        // ���� HTML �ַ���
        var toCreate = 'div';

        if (html.indexOf('<li') === 0) {
          toCreate = 'ul';
        }

        if (html.indexOf('<tr') === 0) {
          toCreate = 'tbody';
        }

        if (html.indexOf('<td') === 0 || html.indexOf('<th') === 0) {
          toCreate = 'tr';
        }

        if (html.indexOf('<tbody') === 0) {
          toCreate = 'table';
        }

        if (html.indexOf('<option') === 0) {
          toCreate = 'select';
        }

        var tempParent = document.createElement(toCreate);
        tempParent.innerHTML = html;

        for (var i = 0; i < tempParent.childNodes.length; i += 1) {
          arr.push(tempParent.childNodes[i]);
        }
      } else {
        // ѡ����
        var elems = selector[0] === '#' && !selector.match(/[ .<>:~]/)
          ? [document.getElementById(selector.slice(1))]
          : document.querySelectorAll(selector);

        for (var i$1 = 0; i$1 < elems.length; i$1 += 1) {
          if (elems[i$1]) {
            arr.push(elems[i$1]);
          }
        }
      }
    } else if (typeof selector === 'function') {
      // function
      return $(document).ready(selector);
    } else if (selector.nodeType || selector === window || selector === document) {
      // Node
      arr.push(selector);
    } else if (selector.length > 0 && selector[0].nodeType) {
      // NodeList
      for (var i$2 = 0; i$2 < selector.length; i$2 += 1) {
        arr.push(selector[i$2]);
      }
    }

    return new JQ(arr);
  }

  $.fn = JQ.prototype;

  function extend() {
    var this$1 = this;
    var args = [], len = arguments.length;
    while ( len-- ) args[ len ] = arguments[ len ];

    if (!args.length) {
      return this;
    }

    // $.extend(obj)
    if (args.length === 1) {
      Object.keys(args[0]).forEach(function (prop) {
        this$1[prop] = args[0][prop];
      });

      return this;
    }

    // $.extend({}, defaults[, obj])
    var target = args.shift();

    var loop = function ( i ) {
      Object.keys(args[i]).forEach(function (prop) {
        target[prop] = args[i][prop];
      });
    };

    for (var i = 0; i < args.length; i += 1) loop( i );

    return target;
  }

  $.fn.extend = extend;
  $.extend = extend;

  /**
   * �ж�һ���ڵ���
   * @param ele
   * @param name
   * @returns {boolean}
   */
  function isNodeName(ele, name) {
    return ele.nodeName && ele.nodeName.toLowerCase() === name.toLowerCase();
  }

  /**
   * ��ȥ null ��� object ����
   * @param obj
   * @returns {*|boolean}
   */
  function isObjectLike(obj) {
    return typeof obj === 'object' && obj !== null;
  }

  function isFunction(fn) {
    return typeof fn === 'function';
  }

  function isString(obj) {
    return typeof obj === 'string';
  }

  function isWindow(win) {
    return win && win === win.window;
  }

  function isDocument(doc) {
    return doc && doc.nodeType === doc.DOCUMENT_NODE;
  }

  function isArrayLike(obj) {
    return typeof obj.length === 'number';
  }

  /**
   * ѭ����������
   * @param obj
   * @param callback
   * @returns {*}
   */
  function each(obj, callback) {
    if (isArrayLike(obj)) {
      for (var i = 0; i < obj.length; i += 1) {
        if (callback.call(obj[i], i, obj[i]) === false) {
          return obj;
        }
      }
    } else {
      var keys = Object.keys(obj);
      for (var i$1 = 0; i$1 < keys.length; i$1 += 1) {
        if (callback.call(obj[keys[i$1]], keys[i$1], obj[keys[i$1]]) === false) {
          return obj;
        }
      }
    }

    return obj;
  }

  /**
   * ������������ͨ����������һ���µ���������null �� undefined �������˵���
   * @param elems
   * @param callback
   * @returns {Array}
   */
  function map(elems, callback) {
    var ref;

    var value;
    var ret = [];

    each(elems, function (i, elem) {
      value = callback(elem, i);

      if (value !== null && value !== undefined) {
        ret.push(value);
      }
    });

    return (ref = []).concat.apply(ref, ret);
  }

  /**
   * �Ѷ���ϲ�����һ�������У������ص�һ������
   * @param first
   * @param second
   * @returns {*}
   */
  function merge(first, second) {
    each(second, function (i, val) {
      first.push(val);
    });

    return first;
  }

  /**
   * ɾ���������ظ�Ԫ��
   * @param arr {Array}
   * @returns {Array}
   */
  function unique(arr) {
    var result = [];

    for (var i = 0; i < arr.length; i += 1) {
      if (result.indexOf(arr[i]) === -1) {
        result.push(arr[i]);
      }
    }

    return result;
  }

  var elementDisplay = {};

  /**
   * ��ȡԪ�ص�Ĭ�� display ��ʽֵ������ .show() ����
   * @param nodeName
   * @returns {*}
   */
  function defaultDisplay(nodeName) {
    var element;
    var display;

    if (!elementDisplay[nodeName]) {
      element = document.createElement(nodeName);
      document.body.appendChild(element);
      display = getComputedStyle(element, '').getPropertyValue('display');
      element.parentNode.removeChild(element);
      if (display === 'none') {
        display = 'block';
      }

      elementDisplay[nodeName] = display;
    }

    return elementDisplay[nodeName];
  }

  $.extend({
    each: each,
    merge: merge,
    unique: unique,
    map: map,

    /**
     * һ�� DOM �ڵ��Ƿ������һ�� DOM �ڵ�
     * @param parent {Node} ���ڵ�
     * @param node {Node} �ӽڵ�
     * @returns {Boolean}
     */
    contains: function contains(parent, node) {
      if (parent && !node) {
        return document.documentElement.contains(parent);
      }

      return parent !== node && parent.contains(node);
    },

    /**
     * �������������л�
     * @param obj
     * @returns {String}
     */
    param: function param(obj) {
      if (!isObjectLike(obj)) {
        return '';
      }

      var args = [];

      function destructure(key, value) {
        var keyTmp;

        if (isObjectLike(value)) {
          each(value, function (i, v) {
            if (Array.isArray(value) && !isObjectLike(v)) {
              keyTmp = '';
            } else {
              keyTmp = i;
            }

            destructure((key + "[" + keyTmp + "]"), v);
          });
        } else {
          if (value !== null && value !== '') {
            keyTmp = "=" + (encodeURIComponent(value));
          } else {
            keyTmp = '';
          }

          args.push(encodeURIComponent(key) + keyTmp);
        }
      }

      each(obj, function (key, value) {
        destructure(key, value);
      });

      return args.join('&');
    },
  });

  $.fn.extend({
    /**
     * ��������
     * @param callback {Function}
     * @return {JQ}
     */
    each: function each$1(callback) {
      return each(this, callback);
    },

    /**
     * ͨ�����������еĽڵ����ͨ����������һ���µĶ���null �� undefined �������˵���
     * @param callback {Function}
     * @returns {JQ}
     */
    map: function map$1(callback) {
      return new JQ(map(this, function (el, i) { return callback.call(el, i, el); }));
    },

    /**
     * ��ȡָ�� DOM Ԫ�أ�û�� index ����ʱ����ȡ���� DOM ������
     * @param index {Number=}
     * @returns {Node|Array}
     */
    get: function get(index) {
      return index === undefined
        ? [].slice.call(this)
        : this[index >= 0 ? index : index + this.length];
    },

    /**
     * array����ȡ�ķ�������start��ʼ�����end ָ������ȡ������endλ�õ�Ԫ�ء�
     * @param args {start, end}
     * @returns {JQ}
     */
    slice: function slice() {
      var args = [], len = arguments.length;
      while ( len-- ) args[ len ] = arguments[ len ];

      return new JQ([].slice.apply(this, args));
    },

    /**
     * ɸѡԪ�ؼ���
     * @param selector {String|JQ|Node|Function}
     * @returns {JQ}
     */
    filter: function filter(selector) {
      if (isFunction(selector)) {
        return this.map(function (index, ele) { return (selector.call(ele, index, ele) ? ele : undefined); });
      }

      var $selector = $(selector);

      return this.map(function (index, ele) { return ($selector.index(ele) > -1 ? ele : undefined); });
    },

    /**
     * ��Ԫ�ؼ�����ɾ��ָ����Ԫ��
     * @param selector {String|Node|JQ|Function}
     * @return {JQ}
     */
    not: function not(selector) {
      var $excludes = this.filter(selector);

      return this.map(function (index, ele) { return ($excludes.index(ele) > -1 ? undefined : ele); });
    },

    /**
     * ��ȡԪ������� document ��ƫ��
     * @returns {Object}
     */
    offset: function offset() {
      if (this[0]) {
        var offset = this[0].getBoundingClientRect();

        return {
          left: offset.left + window.pageXOffset,
          top: offset.top + window.pageYOffset,
          width: offset.width,
          height: offset.height,
        };
      }

      return null;
    },

    /**
     * ������������ڶ�λ�ĸ�Ԫ��
     * @returns {*|JQ}
     */
    offsetParent: function offsetParent() {
      return this.map(function () {
        var parent = this.offsetParent;

        while (parent && $(parent).css('position') === 'static') {
          parent = parent.offsetParent;
        }

        return parent || document.documentElement;
      });
    },

    /**
     * ��ȡԪ������ڸ�Ԫ�ص�ƫ��
     * @return {Object}
     */
    position: function position() {
      var self = this;

      if (!self[0]) {
        return null;
      }

      var offsetParent;
      var offset;
      var parentOffset = {
        top: 0,
        left: 0,
      };

      if (self.css('position') === 'fixed') {
        offset = self[0].getBoundingClientRect();
      } else {
        offsetParent = self.offsetParent();
        offset = self.offset();
        if (!isNodeName(offsetParent[0], 'html')) {
          parentOffset = offsetParent.offset();
        }

        parentOffset = {
          top: parentOffset.top + offsetParent.css('borderTopWidth'),
          left: parentOffset.left + offsetParent.css('borderLeftWidth'),
        };
      }

      return {
        top: offset.top - parentOffset.top - self.css('marginTop'),
        left: offset.left - parentOffset.left - self.css('marginLeft'),
        width: offset.width,
        height: offset.height,
      };
    },

    /**
     * ��ʾָ��Ԫ��
     * @returns {JQ}
     */
    show: function show() {
      return this.each(function () {
        if (this.style.display === 'none') {
          this.style.display = '';
        }

        if (window.getComputedStyle(this, '').getPropertyValue('display') === 'none') {
          this.style.display = defaultDisplay(this.nodeName);
        }
      });
    },

    /**
     * ����ָ��Ԫ��
     * @returns {JQ}
     */
    hide: function hide() {
      return this.each(function () {
        this.style.display = 'none';
      });
    },

    /**
     * �л�Ԫ�ص���ʾ״̬
     * @returns {JQ}
     */
    toggle: function toggle() {
      return this.each(function () {
        this.style.display = this.style.display === 'none' ? '' : 'none';
      });
    },

    /**
     * �Ƿ���ָ���� CSS ��
     * @param className {String}
     * @returns {boolean}
     */
    hasClass: function hasClass(className) {
      if (!this[0] || !className) {
        return false;
      }

      return this[0].classList.contains(className);
    },

    /**
     * �Ƴ�ָ������
     * @param attr {String}
     * @returns {JQ}
     */
    removeAttr: function removeAttr(attr) {
      return this.each(function () {
        this.removeAttribute(attr);
      });
    },

    /**
     * ɾ������ֵ
     * @param name {String}
     * @returns {JQ}
     */
    removeProp: function removeProp(name) {
      return this.each(function () {
        try {
          delete this[name];
        } catch (e) {
          // empty
        }
      });
    },

    /**
     * ��ȡ��ǰ�����е�n��Ԫ��
     * @param index {Number}
     * @returns {JQ}
     */
    eq: function eq(index) {
      var ret = index === -1
        ? this.slice(index)
        : this.slice(index, +index + 1);

      return new JQ(ret);
    },

    /**
     * ��ȡ�����е�һ��Ԫ��
     * @returns {JQ}
     */
    first: function first() {
      return this.eq(0);
    },

    /**
     * ��ȡ���������һ��Ԫ��
     * @returns {JQ}
     */
    last: function last() {
      return this.eq(-1);
    },

    /**
     * ��ȡһ��Ԫ�ص�λ�á�
     * �� elem ����û�и���ʱ�����ص�ǰԪ�����ֵܽڵ��е�λ�á�
     * �и����� elem ����ʱ������ elem Ԫ���ڵ�ǰ�����е�λ��
     * @param elem {Selector|Node=}
     * @returns {Number}
     */
    index: function index(elem) {
      if (!elem) {
        // ��ȡ��ǰ JQ ����ĵ�һ��Ԫ����ͬ��Ԫ���е�λ��
        return this
          .eq(0)
          .parent()
          .children()
          .get()
          .indexOf(this[0]);
      }

      if (isString(elem)) {
        // ���ص�ǰ JQ ����ĵ�һ��Ԫ����ָ��ѡ������Ӧ��Ԫ���е�λ��
        return $(elem)
          .eq(0)
          .parent()
          .children()
          .get()
          .indexOf(this[0]);
      }

      // ����ָ��Ԫ���ڵ�ǰ JQ �����е�λ��
      return this
        .get()
        .indexOf(elem);
    },

    /**
     * ����ѡ������DOMԪ�ػ� JQ ���������ƥ��Ԫ�ؼ��ϣ�
     * �������������һ��Ԫ�ط�����������ı���ʽ�ͷ���true
     * @param selector {String|Node|NodeList|Array|JQ|Window}
     * @returns boolean
     */
    is: function is(selector) {
      var self = this[0];

      if (!self || selector === undefined || selector === null) {
        return false;
      }

      if (isString(selector)) {
        if (self === document || self === window) {
          return false;
        }

        var matchesSelector = self.matches
          || self.matchesSelector
          || self.webkitMatchesSelector
          || self.mozMatchesSelector
          || self.oMatchesSelector
          || self.msMatchesSelector;

        return matchesSelector.call(self, selector);
      }

      if (selector === document || selector === window) {
        return self === selector;
      }

      if (selector.nodeType || isArrayLike(selector)) {
        var $compareWith = selector.nodeType ? [selector] : selector;

        for (var i = 0; i < $compareWith.length; i += 1) {
          if ($compareWith[i] === self) {
            return true;
          }
        }

        return false;
      }

      return false;
    },

    /**
     * ���� CSS ѡ�����ҵ�����ڵ�ļ���
     * @param selector {String}
     * @returns {JQ}
     */
    find: function find(selector) {
      var foundElements = [];

      this.each(function (i, _this) {
        var nodeType = _this.nodeType;

        if (nodeType !== 1 && nodeType !== 9) {
          // ���� element �� document ������
          return;
        }

        merge(foundElements, _this.querySelectorAll(selector));
      });

      return new JQ(foundElements);
    },

    /**
     * �ҵ�ֱ����Ԫ�ص�Ԫ�ؼ���
     * @param selector {String=}
     * @returns {JQ}
     */
    children: function children(selector) {
      var children = [];

      this.each(function (_, _this) {
        each(_this.childNodes, function (__, childNode) {
          if (childNode.nodeType !== 1) {
            return;
          }

          if (!selector || (selector && $(childNode).is(selector))) {
            children.push(childNode);
          }
        });
      });

      return new JQ(unique(children));
    },

    /**
     * ��������ָ����Ԫ�ص�Ԫ�أ�ȥ��������ָ����Ԫ�ص�Ԫ��
     * @param selector {String|Node|JQ|NodeList|Array}
     * @return {JQ}
     */
    has: function has(selector) {
      var $targets = isString(selector) ? this.find(selector) : $(selector);
      var length = $targets.length;

      return this.filter(function () {
        for (var i = 0; i < length; i += 1) {
          if ($.contains(this, $targets[i])) {
            return true;
          }
        }

        return false;
      });
    },

    /**
     * ȡ��ͬ��Ԫ�صļ���
     * @param selector {String=}
     * @returns {JQ}
     */
    siblings: function siblings(selector) {
      return this.prevAll(selector).add(this.nextAll(selector));
    },

    /**
     * ��������ƥ�䵽�ĸ��ڵ㣬�������ڵ�
     * @param selector {String}
     * @returns {JQ}
     */
    closest: function closest(selector) {
      var self = this;

      if (!self.is(selector)) {
        self = self.parents(selector).eq(0);
      }

      return self;
    },

    /**
     * ɾ������ƥ���Ԫ��
     * @returns {JQ}
     */
    remove: function remove() {
      return this.each(function (i, _this) {
        if (_this.parentNode) {
          _this.parentNode.removeChild(_this);
        }
      });
    },

    /**
     * ����ƥ���Ԫ�ص���ǰ������
     * @param selector {String|JQ}
     * @returns {JQ}
     */
    add: function add(selector) {
      return new JQ(unique(merge(this.get(), $(selector))));
    },

    /**
     * ɾ���ӽڵ�
     * @returns {JQ}
     */
    empty: function empty() {
      return this.each(function () {
        this.innerHTML = '';
      });
    },

    /**
     * ͨ����ȿ�¡�����Ƽ����е�����Ԫ�ء�
     * (ͨ��ԭ�� cloneNode ������ȿ�¡�����Ƽ����е�����Ԫ�ء��˷������������ݺ��¼����������Ƶ��µ�Ԫ�ء�����jquery������һ��������ȷ���Ƿ������ݺ��¼���������ͬ��)
     * @returns {JQ}
     */
    clone: function clone() {
      return this.map(function () {
        return this.cloneNode(true);
      });
    },

    /**
     * ����Ԫ���滻��ǰԪ��
     * @param newContent {String|Node|NodeList|JQ}
     * @returns {JQ}
     */
    replaceWith: function replaceWith(newContent) {
      return this.before(newContent).remove();
    },

    /**
     * ������Ԫ�ص�ֵ��ϳɼ�ֵ������
     * @returns {Array}
     */
    serializeArray: function serializeArray() {
      var result = [];
      var elem = this[0];

      if (!elem || !elem.elements) {
        return result;
      }

      $([].slice.call(elem.elements)).each(function () {
        var $elem = $(this);
        var type = $elem.attr('type');
        if (
          this.nodeName.toLowerCase() !== 'fieldset'
          && !this.disabled
          && ['submit', 'reset', 'button'].indexOf(type) === -1
          && (['radio', 'checkbox'].indexOf(type) === -1 || this.checked)
        ) {
          result.push({
            name: $elem.attr('name'),
            value: $elem.val(),
          });
        }
      });

      return result;
    },

    /**
     * ������Ԫ�ػ�������л�
     * @returns {String}
     */
    serialize: function serialize() {
      var result = [];

      each(this.serializeArray(), function (i, elem) {
        result.push(((encodeURIComponent(elem.name)) + "=" + (encodeURIComponent(elem.value))));
      });

      return result.join('&');
    },
  });

  /**
   * val - ��ȡ������Ԫ�ص�ֵ
   * @param value {String=}
   * @return {*|JQ}
   */
  /**
   * html - ��ȡ������Ԫ�ص� HTML
   * @param value {String=}
   * @return {*|JQ}
   */
  /**
   * text - ��ȡ������Ԫ�ص�����
   * @param value {String=}
   * @return {*|JQ}
   */
  each(['val', 'html', 'text'], function (nameIndex, name) {
    var props = {
      0: 'value',
      1: 'innerHTML',
      2: 'textContent',
    };

    var defaults = {
      0: undefined,
      1: undefined,
      2: null,
    };

    $.fn[name] = function (value) {
      if (value === undefined) {
        // ��ȡֵ
        return this[0] ? this[0][props[nameIndex]] : defaults[nameIndex];
      }

      // ����ֵ
      return this.each(function (i, elem) {
        elem[props[nameIndex]] = value;
      });
    };
  });

  /**
   * attr - ��ȡ������Ԫ�ص�����ֵ
   * @param {name|props|key,value=}
   * @return {String|JQ}
   */
  /**
   * prop - ��ȡ������Ԫ�ص�����ֵ
   * @param {name|props|key,value=}
   * @return {String|JQ}
   */
  /**
   * css - ��ȡ������Ԫ�ص���ʽ
   * @param {name|props|key,value=}
   * @return {String|JQ}
   */
  each(['attr', 'prop', 'css'], function (nameIndex, name) {
    function set(elem, key, value) {
      if (nameIndex === 0) {
        elem.setAttribute(key, value);
      } else if (nameIndex === 1) {
        elem[key] = value;
      } else {
        elem.style[key] = value;
      }
    }

    function get(elem, key) {
      if (!elem) {
        return undefined;
      }

      if (nameIndex === 0) {
        return elem.getAttribute(key);
      }

      if (nameIndex === 1) {
        return elem[key];
      }

      return window.getComputedStyle(elem, null).getPropertyValue(key);
    }

    $.fn[name] = function (key, value) {
      var argLength = arguments.length;

      if (argLength === 1 && isString(key)) {
        // ��ȡֵ
        return get(this[0], key);
      }

      // ����ֵ
      return this.each(function (i, elem) {
        if (argLength === 2) {
          set(elem, key, value);
        } else {
          each(key, function (k, v) {
            set(elem, k, v);
          });
        }
      });
    };
  });

  /**
   * addClass - ���� CSS �࣬��������ÿո�ָ�
   * @param className {String}
   * @return {JQ}
   */
  /**
   * removeClass - �Ƴ� CSS �࣬��������ÿո�ָ�
   * @param className {String}
   * @return {JQ}
   */
  /**
   * toggleClass - �л� CSS ��������������ÿո�ָ�
   * @param className {String}
   * @return {JQ}
   */
  each(['add', 'remove', 'toggle'], function (nameIndex, name) {
    $.fn[(name + "Class")] = function (className) {
      if (!className) {
        return this;
      }

      var classes = className.split(' ');

      return this.each(function (i, elem) {
        each(classes, function (j, cls) {
          elem.classList[name](cls);
        });
      });
    };
  });

  /**
   * width - ��ȡԪ�صĿ���
   * @return {Number}
   */
  /**
   * height - ��ȡԪ�صĸ߶�
   * @return {Number}
   */
  each({
    Width: 'width',
    Height: 'height',
  }, function (prop, name) {
    $.fn[name] = function (val) {
      if (val === undefined) {
        // ��ȡ
        var elem = this[0];

        if (isWindow(elem)) {
          return elem[("inner" + prop)];
        }

        if (isDocument(elem)) {
          return elem.documentElement[("scroll" + prop)];
        }

        var $elem = $(elem);

        // IE10��IE11 �� box-sizing:border-box ʱ��������� padding �� border����������޸�
        var IEFixValue = 0;
        var isWidth = name === 'width';
        if ('ActiveXObject' in window) { // �ж��� IE �����
          if ($elem.css('box-sizing') === 'border-box') {
            IEFixValue = parseFloat($elem.css(("padding-" + (isWidth ? 'left' : 'top'))))
              + parseFloat($elem.css(("padding-" + ((isWidth ? 'right' : 'bottom')))))
              + parseFloat($elem.css(("border-" + (isWidth ? 'left' : 'top') + "-width")))
              + parseFloat($elem.css(("border-" + (isWidth ? 'right' : 'bottom') + "-width")));
          }
        }

        return parseFloat($(elem).css(name)) + IEFixValue;
      }

      // ����
      /* eslint no-restricted-globals: 0 */
      if (!isNaN(Number(val)) && val !== '') {
        val += 'px';
      }

      return this.css(name, val);
    };
  });

  /**
   * innerWidth - ��ȡԪ�صĿ��ȣ������ڱ߾�
   * @return {Number}
   */
  /**
   * innerHeight - ��ȡԪ�صĸ߶ȣ������ڱ߾�
   * @return {Number}
   */
  each({
    Width: 'width',
    Height: 'height',
  }, function (prop, name) {
    $.fn[("inner" + prop)] = function () {
      var value = this[name]();
      var $elem = $(this[0]);

      if ($elem.css('box-sizing') !== 'border-box') {
        value += parseFloat($elem.css(("padding-" + (name === 'width' ? 'left' : 'top'))));
        value += parseFloat($elem.css(("padding-" + (name === 'width' ? 'right' : 'bottom'))));
      }

      return value;
    };
  });

  function dir(nodes, selector, nameIndex, node) {
    var ret = [];
    var elem;

    nodes.each(function (j, _this) {
      elem = _this[node];
      while (elem) {
        if (nameIndex === 2) {
          // prevUntil
          if (!selector || (selector && $(elem).is(selector))) {
            break;
          }

          ret.push(elem);
        } else if (nameIndex === 0) {
          // prev
          if (!selector || (selector && $(elem).is(selector))) {
            ret.push(elem);
          }

          break;
        } else if (!selector || (selector && $(elem).is(selector))) {
          // prevAll
          ret.push(elem);
        }

        elem = elem[node];
      }
    });

    return new JQ(unique(ret));
  }

  /**
   * prev - ȡ��ǰһ��ƥ���Ԫ��
   * @param selector {String=}
   * @return {JQ}
   */
  /**
   * prevAll - ȡ��ǰ������ƥ���Ԫ��
   * @param selector {String=}
   * @return {JQ}
   */
  /**
   * prevUntil - ȡ��ǰ�������Ԫ�أ�ֱ������ƥ���Ԫ�أ�������ƥ���Ԫ��
   * @param selector {String=}
   * @return {JQ}
   */
  each(['', 'All', 'Until'], function (nameIndex, name) {
    $.fn[("prev" + name)] = function (selector) {
      // prevAll��prevUntil ��Ҫ��Ԫ�ص�˳���������Ա�� jQuery �Ľ��һ��
      var $nodes = nameIndex === 0 ? this : $(this.get().reverse());

      return dir($nodes, selector, nameIndex, 'previousElementSibling');
    };
  });

  /**
   * next - ȡ�ú�һ��ƥ���Ԫ��
   * @param selector {String=}
   * @return {JQ}
   */
  /**
   * nextAll - ȡ�ú�������ƥ���Ԫ��
   * @param selector {String=}
   * @return {JQ}
   */
  /**
   * nextUntil - ȡ�ú�������ƥ���Ԫ�أ�ֱ������ƥ���Ԫ�أ�������ƥ���Ԫ��
   * @param selector {String=}
   * @return {JQ}
   */
  each(['', 'All', 'Until'], function (nameIndex, name) {
    $.fn[("next" + name)] = function (selector) {
      return dir(this, selector, nameIndex, 'nextElementSibling');
    };
  });

  /**
   * parent - ȡ��ƥ���ֱ�Ӹ�Ԫ��
   * @param selector {String=}
   * @return {JQ}
   */
  /**
   * parents - ȡ������ƥ��ĸ�Ԫ��
   * @param selector {String=}
   * @return {JQ}
   */
  /**
   * parentUntil - ȡ�����еĸ�Ԫ�أ�ֱ������ƥ���Ԫ�أ�������ƥ���Ԫ��
   * @param selector {String=}
   * @return {JQ}
   */
  each(['', 's', 'sUntil'], function (nameIndex, name) {
    $.fn[("parent" + name)] = function (selector) {
      // parents��parentsUntil ��Ҫ��Ԫ�ص�˳���������Ա�� jQuery �Ľ��һ��
      var $nodes = nameIndex === 0 ? this : $(this.get().reverse());

      return dir($nodes, selector, nameIndex, 'parentNode');
    };
  });

  /**
   * append - ��Ԫ���ڲ�׷������
   * @param newChild {String|Node|NodeList|JQ}
   * @return {JQ}
   */
  /**
   * prepend - ��Ԫ���ڲ�ǰ������
   * @param newChild {String|Node|NodeList|JQ}
   * @return {JQ}
   */
  each(['append', 'prepend'], function (nameIndex, name) {
    $.fn[name] = function (newChild) {
      var newChilds;
      var copyByClone = this.length > 1;

      if (isString(newChild) && (newChild[0] !== '<' || newChild[newChild.length - 1] !== '>')) {
        var tempDiv = document.createElement('div');
        tempDiv.innerHTML = newChild;
        newChilds = [].slice.call(tempDiv.childNodes);
      } else {
        newChilds = $(newChild).get();
      }

      if (nameIndex === 1) {
        // prepend
        newChilds.reverse();
      }

      return this.each(function (i, _this) {
        each(newChilds, function (j, child) {
          // һ��Ԫ��Ҫͬʱ׷�ӵ����Ԫ���У���Ҫ�ȸ���һ�ݣ�Ȼ��׷��
          if (copyByClone && i > 0) {
            child = child.cloneNode(true);
          }

          if (nameIndex === 0) {
            // append
            _this.appendChild(child);
          } else {
            // prepend
            _this.insertBefore(child, _this.childNodes[0]);
          }
        });
      });
    };
  });

  /**
   * insertBefore - ���뵽ָ��Ԫ�ص�ǰ��
   * @param selector {String|Node|NodeList|JQ}
   * @return {JQ}
   */
  /**
   * insertAfter - ���뵽ָ��Ԫ�صĺ���
   * @param selector {String|Node|NodeList|JQ}
   * @return {JQ}
   */
  each(['insertBefore', 'insertAfter'], function (nameIndex, name) {
    $.fn[name] = function (selector) {
      var $elem = $(selector);

      return this.each(function (i, _this) {
        $elem.each(function (j, elem) {
          elem.parentNode.insertBefore(
            $elem.length === 1 ? _this : _this.cloneNode(true),
            nameIndex === 0 ? elem : elem.nextSibling
          );
        });
      });
    };
  });

  /**
   * appendTo - ׷�ӵ�ָ��Ԫ������
   * @param selector {String|Node|NodeList|JQ}
   * @return {JQ}
   */
  /**
   * prependTo - ǰ�õ�ָ��Ԫ���ڲ�
   * @param selector {String|Node|NodeList|JQ}
   * @return {JQ}
   */
  /**
   * before - ���뵽ָ��Ԫ��ǰ��
   * @param selector {String|Node|NodeList|JQ}
   * @return {JQ}
   */
  /**
   * after - ���뵽ָ��Ԫ�غ���
   * @param selector {String|Node|NodeList|JQ}
   * @return {JQ}
   */
  /**
   * replaceAll - �滻��ָ��Ԫ��
   * @param selector {String|Node|NodeList|JQ}
   * @return {JQ}
   */
  each({
    appendTo: 'append',
    prependTo: 'prepend',
    before: 'insertBefore',
    after: 'insertAfter',
    replaceAll: 'replaceWith',
  }, function (name, original) {
    $.fn[name] = function (selector) {
      $(selector)[original](this);
      return this;
    };
  });

  var dataNS = 'mduiElementDataStorage';

  $.extend({
    /**
     * ��ָ��Ԫ���ϴ洢���ݣ����ָ��Ԫ���϶�ȡ����
     * @param elem ���룬 DOM Ԫ��
     * @param key ���룬����
     * @param value ��ѡ��ֵ
     */
    data: function data(elem, key, value) {
      var data = {};

      if (value !== undefined) {
        // ���� key��value ����ֵ
        data[key] = value;
      } else if (isObjectLike(key)) {
        // ���ݼ�ֵ������ֵ
        data = key;
      } else if (key === undefined) {
        // ��ȡ����ֵ
        var result = {};

        each(elem.attributes, function (i, attribute) {
          var name = attribute.name;

          if (name.indexOf('data-') === 0) {
            var prop = name.slice(5).replace(/-./g, function (u) { return u.charAt(1).toUpperCase(); });

            result[prop] = attribute.value;
          }
        });

        if (elem[dataNS]) {
          each(elem[dataNS], function (k, v) {
            result[k] = v;
          });
        }

        return result;
      } else if (elem[dataNS] && (key in elem[dataNS])) {
        // ��ȡָ��ֵ
        return elem[dataNS][key];
      } else {
        // �� data- �л�ȡָ��ֵ
        var dataKey = elem.getAttribute(("data-" + key));

        if (dataKey) {
          return dataKey;
        }

        return undefined;
      }

      // ����ֵ
      if (!elem[dataNS]) {
        elem[dataNS] = {};
      }

      each(data, function (k, v) {
        elem[dataNS][k] = v;
      });

      return undefined;
    },

    /**
     * �Ƴ�ָ��Ԫ���ϴ�ŵ�����
     * @param elem ���룬DOM Ԫ��
     * @param key ���룬����
     */
    removeData: function removeData(elem, key) {
      if (elem[dataNS] && elem[dataNS][key]) {
        elem[dataNS][key] = null;
        delete elem[dataNS][key];
      }
    },
  });

  $.fn.extend({
    /**
     * ��Ԫ���϶�ȡ����������
     * @param key ����
     * @param value
     * @returns {*}
     */
    data: function data(key, value) {
      if (value === undefined) {
        if (isObjectLike(key)) {
          // ͬʱ���ö��ֵ
          return this.each(function (i, elem) {
            $.data(elem, key);
          });
        }

        if (this[0]) {
          // ��ȡֵ
          return $.data(this[0], key);
        }

        return undefined;
      }

      // ����ֵ
      return this.each(function (i, elem) {
        $.data(elem, key, value);
      });
    },

    /**
     * �Ƴ�Ԫ���ϴ洢������
     * @param key ����
     * @returns {*}
     */
    removeData: function removeData(key) {
      return this.each(function (i, elem) {
        $.removeData(elem, key);
      });
    },
  });

  !function(){try{return new e("test")}catch(e){}var e=function(e,t){t=t||{bubbles:!1,cancelable:!1};var n=document.createEvent("MouseEvent");return n.initMouseEvent(e,t.bubbles,t.cancelable,window,0,0,0,0,0,!1,!1,!1,!1,0,null),n};e.prototype=Event.prototype,window.MouseEvent=e;}();

  !function(){function t(t,e){e=e||{bubbles:!1,cancelable:!1,detail:void 0};var n=document.createEvent("CustomEvent");return n.initCustomEvent(t,e.bubbles,e.cancelable,e.detail),n}"function"!=typeof window.CustomEvent&&(t.prototype=window.Event.prototype,window.CustomEvent=t);}();

  // �洢�¼�
  var handlers = {
    // i: { // Ԫ��ID
    //   j: { // �¼�ID
    //     e: �¼���
    //     fn: �¼���������
    //     i: �¼�ID
    //     proxy:
    //     sel: ѡ����
    //   }
    // }
  };

  // Ԫ��ID
  var mduiElementId = 1;

  function fnFalse() {
    return false;
  }

  /**
   * ΪԪ�ظ���һ��Ψһ��ID
   * @param element
   * @returns {number|*}
   */
  function getElementId(element) {
    if (!element.mduiElementId) {
      mduiElementId += 1;
      element.mduiElementId = mduiElementId;
    }

    return element.mduiElementId;
  }

  /**
   * ��ȡƥ����¼�
   * @param element
   * @param eventName
   * @param func
   * @param selector
   * @returns {Array}
   */
  function getHandlers(element, eventName, func, selector) {
    return (handlers[getElementId(element)] || []).filter(function (handler) { return handler
      && (!eventName || handler.e === eventName)
      && (!func || handler.fn.toString() === func.toString())
      && (!selector || handler.sel === selector); });
  }

  /**
   * �����¼�����
   * @param element
   * @param eventName
   * @param func
   * @param data
   * @param selector
   */
  function add(element, eventName, func, data, selector) {
    var elementId = getElementId(element);

    if (!handlers[elementId]) {
      handlers[elementId] = [];
    }

    // ���� data.useCapture ������ useCapture: true
    var useCapture = false;
    if (isObjectLike(data) && data.useCapture) {
      useCapture = true;
    }

    eventName.split(' ').forEach(function (event) {
      var handler = {
        e: event,
        fn: func,
        sel: selector,
        i: handlers[elementId].length,
      };

      function callFn(e, elem) {
        // ��Ϊ����¼�ģ���¼��� detail ������ֻ���ģ������ e._detail �д洢����
        /* eslint no-underscore-dangle: 0 */
        var result = func.apply(elem, e._detail === undefined ? [e] : [e].concat(e._detail));

        if (result === false) {
          e.preventDefault();
          e.stopPropagation();
        }
      }

      function proxyfn(e) {
        e._data = data;

        if (selector) {
          // �¼�����
          $(element)
            .find(selector)
            .get()
            .reverse()
            .forEach(function (elem) {
              if (elem === e.target || $.contains(elem, e.target)) {
                callFn(e, elem);
              }
            });
        } else {
          // ��ʹ���¼�����
          callFn(e, element);
        }
      }

      handler.proxy = proxyfn;
      handlers[elementId].push(handler);
      element.addEventListener(handler.e, proxyfn, useCapture);
    });
  }

  /**
   * �Ƴ��¼�����
   * @param element
   * @param eventName
   * @param func
   * @param selector
   */
  function remove(element, eventName, func, selector) {
    (eventName || '').split(' ').forEach(function (event) {
      getHandlers(element, event, func, selector).forEach(function (handler) {
        delete handlers[getElementId(element)][handler.i];
        element.removeEventListener(handler.e, handler.proxy, false);
      });
    });
  }

  $.fn.extend({
    /**
     * DOM ������Ϻ���õĺ���
     * @param callback
     * @returns {ready}
     */
    ready: function ready(callback) {
      if (/complete|loaded|interactive/.test(document.readyState) && document.body) {
        callback($);
      } else {
        document.addEventListener('DOMContentLoaded', function () {
          callback($);
        }, false);
      }

      return this;
    },

    /**
     * ���¼�
     *
     * $().on({eventName: fn}, selector, data);
     * $().on({eventName: fn}, selector)
     * $().on({eventName: fn})
     * $().on(eventName, selector, data, fn);
     * $().on(eventName, selector, fn);
     * $().on(eventName, data, fn);
     * $().on(eventName, fn);
     * $().on(eventName, false);
     *
     * @param eventName
     * @param selector
     * @param data
     * @param callback
     * @param one �Ƿ��� one ������ֻ�� JQ �ڲ�ʹ��
     * @returns
     */
    on: function on(eventName, selector, data, callback, one) {
      var self = this;

      // Ĭ��
      // $().on(event, selector, data, callback)

      // event ʹ�� �¼�:���� ��ֵ��
      // event = {
      //   'event1': callback1,
      //   'event2': callback2
      // }
      //
      // $().on(event, selector, data)
      if (eventName && !isString(eventName)) {
        each(eventName, function (type, fn) {
          self.on(type, selector, data, fn);
        });

        return self;
      }

      // selector ������
      // $().on(event, data, callback)
      if (!isString(selector) && !isFunction(callback) && callback !== false) {
        callback = data;
        data = selector;
        selector = undefined;
      }

      // data ������
      // $().on(event, callback)
      if (isFunction(data) || data === false) {
        callback = data;
        data = undefined;
      }

      // callback Ϊ false
      // $().on(event, false)
      if (callback === false) {
        callback = fnFalse;
      }

      if (one === 1) {
        var origCallback = callback;
        callback = function () {
          self.off(eventName, selector, callback);
          /* eslint prefer-rest-params: 0 */
          return origCallback.apply(this, arguments);
        };
      }

      return this.each(function () {
        add(this, eventName, callback, data, selector);
      });
    },

    /**
     * ���¼���ֻ����һ��
     * @param eventName
     * @param selector
     * @param data
     * @param callback
     */
    one: function one(eventName, selector, data, callback) {
      var self = this;

      if (!isString(eventName)) {
        each(eventName, function (type, fn) {
          type.split(' ').forEach(function (eName) {
            self.on(eName, selector, data, fn, 1);
          });
        });
      } else {
        eventName.split(' ').forEach(function (eName) {
          self.on(eName, selector, data, callback, 1);
        });
      }

      return this;
    },

    /**
     * ȡ�����¼�
     *
     * $().off(eventName, selector);
     * $().off(eventName, callback);
     * $().off(eventName, false);
     *
     */
    off: function off(eventName, selector, callback) {
      var self = this;

      // event ʹ�� �¼�:���� ��ֵ��
      // event = {
      //   'event1': callback1,
      //   'event2': callback2
      // }
      //
      // $().off(event, selector)
      if (eventName && !isString(eventName)) {
        each(eventName, function (type, fn) {
          self.off(type, selector, fn);
        });

        return self;
      }

      // selector ������
      // $().off(event, callback)
      if (!isString(selector) && !isFunction(callback) && callback !== false) {
        callback = selector;
        selector = undefined;
      }

      // callback Ϊ false
      // $().off(event, false)
      if (callback === false) {
        callback = fnFalse;
      }

      return self.each(function () {
        remove(this, eventName, callback, selector);
      });
    },

    /**
     * ����һ���¼�
     * @param eventName
     * @param data
     * @returns {*|JQ}
     */
    trigger: function trigger(eventName, data) {
      var isMouseEvent = ['click', 'mousedown', 'mouseup', 'mousemove'].indexOf(eventName) > -1;
      var evt;

      if (isMouseEvent) {
        // Note: MouseEvent �޷����� detail ����
        evt = new MouseEvent(eventName, {
          bubbles: true,
          cancelable: true,
        });
      } else {
        evt = new CustomEvent(eventName, {
          detail: data,
          bubbles: true,
          cancelable: true,
        });
      }

      evt._detail = data;

      return this.each(function () {
        this.dispatchEvent(evt);
      });
    },
  });

  var globalOptions = {};
  var jsonpID = 0;

  // ȫ���¼���
  var ajaxEvent = {
    ajaxStart: 'start.mdui.ajax',
    ajaxSuccess: 'success.mdui.ajax',
    ajaxError: 'error.mdui.ajax',
    ajaxComplete: 'complete.mdui.ajax',
  };

  /**
   * �жϴ����󷽷��Ƿ�ͨ����ѯ�ַ����ύ����
   * @param method ���󷽷�����д
   * @returns {boolean}
   */
  function isQueryStringData(method) {
    return ['GET', 'HEAD'].indexOf(method) >= 0;
  }

  /**
   * ���Ӳ����� URL �ϣ��� URL �в����� ? ʱ���Զ��ѵ�һ�� & �滻Ϊ ?
   * @param url
   * @param query ���� key=value
   * @returns {string}
   */
  function appendQuery(url, query) {
    return ((url + "&" + query)).replace(/[&?]{1,2}/, '?');
  }

  $.extend({

    /**
     * Ϊ ajax ��������ȫ�����ò���
     * @param options
     */
    ajaxSetup: function ajaxSetup(options) {
      $.extend(globalOptions, options || {});
    },

    /**
     * ���� ajax ����
     * @param options
     */
    ajax: function ajax(options) {
      // ���ò���
      var defaults = {
        // ����ʽ
        method: 'GET',
        // ��������ݣ���ѯ�ַ��������
        data: false,
        // �Ƿ������ת��Ϊ��ѯ�ַ������ͣ�Ϊ false ʱ�������Զ�ת����
        processData: true,
        // �Ƿ�Ϊ�첽����
        async: true,
        // �Ƿ�ӻ����ж�ȡ��ֻ�� GET/HEAD ������Ч��dataType Ϊ jsonp ʱΪ false
        cache: true,
        // HTTP ������֤���û���
        username: '',
        // HTTP ������֤������
        password: '',
        // һ����ֵ�ԣ���������һ����
        headers: {},
        // ���� XHR ����
        xhrFields: {},
        // һ�� HTTP ����ͺ����Ķ���
        statusCode: {},
        // Ԥ�ڷ��������ص��������� text��json��jsonp
        dataType: 'text',
        // jsonp ����Ļص���������
        jsonp: 'callback',
        // ��string �� Function��ʹ��ָ���Ļص������������Զ����ɵĻص�������
        jsonpCallback: function () {
          jsonpID += 1;

          return ("mduijsonp_" + (Date.now()) + "_" + jsonpID);
        },
        // ������Ϣ��������ʱ���ݱ�������
        contentType: 'application/x-www-form-urlencoded',
        // ��������ʱʱ�䣨���룩
        timeout: 0,
        // �Ƿ��� document �ϴ���ȫ�� ajax �¼�
        global: true,
        // beforeSend:    function (XMLHttpRequest) ������ǰʵ�У����� false ��ȡ������ ajax ����
        // success:       function (data, textStatus, XMLHttpRequest) ����ɹ�ʱ����
        // error:         function (XMLHttpRequest, textStatus) ����ʧ��ʱ����
        // statusCode:    {404: function ()}
        //                200-299֮���״̬���ʾ�ɹ��������� success �ص�һ��������״̬���ʾʧ�ܣ������� error �ص�һ��
        // complete:      function (XMLHttpRequest, textStatus) ������ɺ�ص����� (����ɹ���ʧ��֮�������)
      };

      // �ص�����
      var callbacks = [
        'beforeSend',
        'success',
        'error',
        'statusCode',
        'complete' ];

      // �Ƿ���ȡ������
      var isCanceled = false;

      // ����ȫ������
      var globals = globalOptions;

      // �¼�����
      var eventParams = {};

      // �ϲ�ȫ�ֲ�����Ĭ�ϲ�����ȫ�ֻص�����������
      each(globals, function (key, value) {
        if (callbacks.indexOf(key) < 0) {
          defaults[key] = value;
        }
      });

      // �����ϲ�
      options = $.extend({}, defaults, options);

      /**
       * ����ȫ���¼�
       * @param event string �¼���
       * @param xhr XMLHttpRequest �¼�����
       */
      function triggerEvent(event, xhr) {
        if (options.global) {
          $(document).trigger(event, xhr);
        }
      }

      /**
       * ���� XHR �ص����¼�
       * @param callback string �ص���������
       * @param args
       */
      function triggerCallback(callback) {
        var args = [], len = arguments.length - 1;
        while ( len-- > 0 ) args[ len ] = arguments[ len + 1 ];

        var result1;
        var result2;

        if (callback) {
          // ȫ�ֻص�
          if (callback in globals) {
            result1 = globals[callback].apply(globals, args);
          }

          // �Զ���ص�
          if (options[callback]) {
            result2 = options[callback].apply(options, args);
          }

          // beforeSend �ص����� false ʱȡ�� ajax ����
          if (callback === 'beforeSend' && (result1 === false || result2 === false)) {
            isCanceled = true;
          }
        }
      }

      // ����ʽתΪ��д
      var method = options.method.toUpperCase();

      // Ĭ��ʹ�õ�ǰҳ�� URL
      if (!options.url) {
        options.url = window.location.toString();
      }

      // ��Ҫ���͵�����
      // GET/HEAD ����� processData Ϊ true ʱ��ת��Ϊ��ѯ�ַ�����ʽ�������ʽ��ת��
      var sendData;
      if (
        (isQueryStringData(method) || options.processData)
        && options.data
        && [ArrayBuffer, Blob, Document, FormData].indexOf(options.data.constructor) < 0
      ) {
        sendData = isString(options.data) ? options.data : $.param(options.data);
      } else {
        sendData = options.data;
      }

      // ���� GET��HEAD ���͵����󣬰� data �������ӵ� URL ��
      if (isQueryStringData(method) && sendData) {
        // ��ѯ�ַ���ƴ�ӵ� URL ��
        options.url = appendQuery(options.url, sendData);
        sendData = null;
      }

      // JSONP
      if (options.dataType === 'jsonp') {
        // URL �������Զ����ɵĻص�������
        var callbackName = isFunction(options.jsonpCallback)
          ? options.jsonpCallback()
          : options.jsonpCallback;
        var requestUrl = appendQuery(options.url, ((options.jsonp) + "=" + callbackName));

        eventParams.options = options;

        triggerEvent(ajaxEvent.ajaxStart, eventParams);
        triggerCallback('beforeSend', null);

        if (isCanceled) {
          return undefined;
        }

        var abortTimeout;

        // ���� script
        var script = document.createElement('script');
        script.type = 'text/javascript';

        // ���� script ʧ��
        script.onerror = function () {
          if (abortTimeout) {
            clearTimeout(abortTimeout);
          }

          triggerEvent(ajaxEvent.ajaxError, eventParams);
          triggerCallback('error', null, 'scripterror');

          triggerEvent(ajaxEvent.ajaxComplete, eventParams);
          triggerCallback('complete', null, 'scripterror');
        };

        script.src = requestUrl;

        // ����
        window[callbackName] = function (data) {
          if (abortTimeout) {
            clearTimeout(abortTimeout);
          }

          eventParams.data = data;

          triggerEvent(ajaxEvent.ajaxSuccess, eventParams);
          triggerCallback('success', data, 'success', null);

          $(script).remove();
          script = null;
          delete window[callbackName];
        };

        $('head').append(script);

        if (options.timeout > 0) {
          abortTimeout = setTimeout(function () {
            $(script).remove();
            script = null;

            triggerEvent(ajaxEvent.ajaxError, eventParams);
            triggerCallback('error', null, 'timeout');
          }, options.timeout);
        }

        return undefined;
      }

      // GET/HEAD ����Ļ��洦��
      if (isQueryStringData(method) && !options.cache) {
        options.url = appendQuery(options.url, ("_=" + (Date.now())));
      }

      // ���� XHR
      var xhr = new XMLHttpRequest();

      xhr.open(method, options.url, options.async, options.username, options.password);

      if (
        options.contentType
        || (
          sendData
          && !isQueryStringData(method)
          && options.contentType !== false
        )
      ) {
        xhr.setRequestHeader('Content-Type', options.contentType);
      }

      // ���� Accept
      if (options.dataType === 'json') {
        xhr.setRequestHeader('Accept', 'application/json, text/javascript');
      }

      // ���� headers
      if (options.headers) {
        each(options.headers, function (key, value) {
          xhr.setRequestHeader(key, value);
        });
      }

      // ����Ƿ��ǿ�������
      if (options.crossDomain === undefined) {
        options.crossDomain = /^([\w-]+:)?\/\/([^/]+)/.test(options.url)
          && RegExp.$2 !== window.location.host;
      }

      if (!options.crossDomain) {
        xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
      }

      if (options.xhrFields) {
        each(options.xhrFields, function (key, value) {
          xhr[key] = value;
        });
      }

      eventParams.xhr = xhr;
      eventParams.options = options;

      var xhrTimeout;

      xhr.onload = function () {
        if (xhrTimeout) {
          clearTimeout(xhrTimeout);
        }

        // �����ɹ�����������ַ���
        var textStatus;

        // AJAX ���ص� HTTP ��Ӧ���Ƿ��ʾ�ɹ�
        var isHttpStatusSuccess = (xhr.status >= 200 && xhr.status < 300) || xhr.status === 0;

        var responseData;

        if (isHttpStatusSuccess) {
          if (xhr.status === 204 || method === 'HEAD') {
            textStatus = 'nocontent';
          } else if (xhr.status === 304) {
            textStatus = 'notmodified';
          } else {
            textStatus = 'success';
          }

          if (options.dataType === 'json') {
            try {
              responseData = JSON.parse(xhr.responseText);
              eventParams.data = responseData;
            } catch (err) {
              textStatus = 'parsererror';

              triggerEvent(ajaxEvent.ajaxError, eventParams);
              triggerCallback('error', xhr, textStatus);
            }

            if (textStatus !== 'parsererror') {
              triggerEvent(ajaxEvent.ajaxSuccess, eventParams);
              triggerCallback('success', responseData, textStatus, xhr);
            }
          } else {
            responseData = xhr.responseType === 'text' || xhr.responseType === ''
              ? xhr.responseText
              : xhr.response;
            eventParams.data = responseData;

            triggerEvent(ajaxEvent.ajaxSuccess, eventParams);
            triggerCallback('success', responseData, textStatus, xhr);
          }
        } else {
          textStatus = 'error';

          triggerEvent(ajaxEvent.ajaxError, eventParams);
          triggerCallback('error', xhr, textStatus);
        }

        // statusCode
        each([globals.statusCode, options.statusCode], function (i, func) {
          if (func && func[xhr.status]) {
            if (isHttpStatusSuccess) {
              func[xhr.status](responseData, textStatus, xhr);
            } else {
              func[xhr.status](xhr, textStatus);
            }
          }
        });

        triggerEvent(ajaxEvent.ajaxComplete, eventParams);
        triggerCallback('complete', xhr, textStatus);
      };

      xhr.onerror = function () {
        if (xhrTimeout) {
          clearTimeout(xhrTimeout);
        }

        triggerEvent(ajaxEvent.ajaxError, eventParams);
        triggerCallback('error', xhr, xhr.statusText);

        triggerEvent(ajaxEvent.ajaxComplete, eventParams);
        triggerCallback('complete', xhr, 'error');
      };

      xhr.onabort = function () {
        var textStatus = 'abort';

        if (xhrTimeout) {
          textStatus = 'timeout';
          clearTimeout(xhrTimeout);
        }

        triggerEvent(ajaxEvent.ajaxError, eventParams);
        triggerCallback('error', xhr, textStatus);

        triggerEvent(ajaxEvent.ajaxComplete, eventParams);
        triggerCallback('complete', xhr, textStatus);
      };

      // ajax start �ص�
      triggerEvent(ajaxEvent.ajaxStart, eventParams);
      triggerCallback('beforeSend', xhr);

      if (isCanceled) {
        return xhr;
      }

      // Timeout
      if (options.timeout > 0) {
        xhrTimeout = setTimeout(function () {
          xhr.abort();
        }, options.timeout);
      }

      // ���� XHR
      xhr.send(sendData);

      return xhr;
    },
  });

  // ����ȫ���¼�
  //
  // ͨ�� $(document).on('success.mdui.ajax', function (event, params) {}) ����ʱ��������������
  // event: �¼�����
  // params: {
  //   xhr: XMLHttpRequest ����
  //   options: ajax ��������ò���
  //   data: ajax ���󷵻ص�����
  // }

  // ȫ�� Ajax �¼���ݷ���
  // $(document).ajaxStart(function (event, xhr, options) {})
  // $(document).ajaxSuccess(function (event, xhr, options, data) {})
  // $(document).ajaxError(function (event, xhr, options) {})
  // $(document).ajaxComplete(function (event, xhr, options) {})
  each(ajaxEvent, function (name, eventName) {
    $.fn[name] = function (fn) {
      return this.on(eventName, function (e, params) {
        fn(e, params.xhr, params.options, params.data);
      });
    };
  });

  return $;

}());
