/**
 * =============================================================================
 * ************   mdui.alert(text, title, onConfirm, options)   ************
 * ************   mdui.alert(text, onConfirm, options)   ************
 * =============================================================================
 */

mdui.alert = function (text, title, onConfirm, options) {

  // title ������ѡ
  if (typeof title === 'function') {
    title = '';
    onConfirm = arguments[1];
    options = arguments[2];
  }

  if (onConfirm === undefined) {
    onConfirm = function () {};
  }

  if (options === undefined) {
    options = {};
  }

  /**
   * Ĭ�ϲ���
   */
  var DEFAULT = {
    confirmText: 'ok',             // ��ť�ϵ��ı�
    history: true,                 // ���� hashchange �¼�
    modal: false,                  // �Ƿ�ģ̬���Ի���Ϊ false ʱ����Ի�����������رնԻ���Ϊ true ʱ���ر�
    closeOnEsc: true,              // ���� esc �رնԻ���
  };

  options = $.extend({}, DEFAULT, options);

  return mdui.dialog({
    title: title,
    content: text,
    buttons: [
      {
        text: options.confirmText,
        bold: false,
        close: true,
        onClick: onConfirm,
      },
    ],
    cssClass: 'mdui-dialog-alert',
    history: options.history,
    modal: options.modal,
    closeOnEsc: options.closeOnEsc,
  });
};
