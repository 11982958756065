/**
 * =============================================================================
 * ************   Mutation   ************
 * =============================================================================
 */

(function () {
  /**
   * API ��ʼ������, �� DOM ͻ���ٴ�ʵ�д����ĳ�ʼ������. ʹ�÷���:
   *
   * 1. ������� API ʵ�г�ʼ������, selector ����Ϊ�ַ���.
   *    mdui.mutation(selector, apiInit);
   *    mutation ��ʵ�� $(selector).each(apiInit)
   *
   * 2. ͻ��ʱ, �ٴ�ʵ�д����ĳ�ʼ������
   *    mdui.mutation()        �ȼ� $(document).mutation()
   *    $(selector).mutation() �� selector �ڵ��ڽ��� API ��ʼ��
   *
   * ԭ��:
   *
   *    mutation ʵ���� $().data('mdui.mutation', [selector]).
   *    ��Ԫ�ر��ع�ʱ, �����ݻᶪʧ, �ɴ��ж��Ƿ�ͻ��.
   *
   * ��ʾ:
   *
   *    ���� Drawer ����ʹ��ί���¼����.
   *    ���� Collapse ��Ҫ֪�� DOM ����ͻ��, ���ٴν��г�ʼ��.
   */
  var entries = { };

  function mutation(selector, apiInit, that, i, item) {
    var $this = $(that);
    var m = $this.data('mdui.mutation');

    if (!m) {
      m = [];
      $this.data('mdui.mutation', m);
    }

    if (m.indexOf(selector) === -1) {
      m.push(selector);
      apiInit.call(that, i, item);
    }
  }

  $.fn.extend({
    mutation: function () {
      return this.each(function (i, item) {
        var $this = $(this);
        $.each(entries, function (selector, apiInit) {
          if ($this.is(selector)) {
            mutation(selector, apiInit, $this[0], i, item);
          }

          $this.find(selector).each(function (i, item) {
            mutation(selector, apiInit, this, i, item);
          });
        });
      });
    },
  });

  mdui.mutation = function (selector, apiInit) {
    if (typeof selector !== 'string' || typeof apiInit !== 'function') {
      $(document).mutation();
      return;
    }

    entries[selector] = apiInit;
    $(selector).each(function (i, item) {
      mutation(selector, apiInit, this, i, item);
    });
  };

})();
