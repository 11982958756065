/**
 * =============================================================================
 * ************   Fab ����������ť   ************
 * =============================================================================
 */

mdui.Fab = (function () {

  /**
   * Ĭ�ϲ���
   * @type {{}}
   */
  var DEFAULT = {
    trigger: 'hover',      // ������ʽ ['hover', 'click']
  };

  /**
   * ����������ťʵ��
   * @param selector ѡ������ HTML �ַ����� DOM Ԫ�ػ� JQ ����
   * @param opts
   * @constructor
   */
  function Fab(selector, opts) {
    var _this = this;

    _this.$fab = $(selector).eq(0);
    if (!_this.$fab.length) {
      return;
    }

    // ��ͨ�� data ����ʵ�������������ظ�ʵ����
    var oldInst = _this.$fab.data('mdui.fab');
    if (oldInst) {
      return oldInst;
    }

    _this.options = $.extend({}, DEFAULT, (opts || {}));
    _this.state = 'closed';

    _this.$btn = _this.$fab.find('.mdui-fab');
    _this.$dial = _this.$fab.find('.mdui-fab-dial');
    _this.$dialBtns = _this.$dial.find('.mdui-fab');

    if (_this.options.trigger === 'hover') {
      _this.$btn
        .on('touchstart mouseenter', function () {
          _this.open();
        });

      _this.$fab
        .on('mouseleave', function () {
          _this.close();
        });
    }

    if (_this.options.trigger === 'click') {
      _this.$btn
        .on(TouchHandler.start, function () {
          _this.open();
        });
    }

    // ������Ļ�����ط��رտ��ٲ���
    $document.on(TouchHandler.start, function (e) {
      if (!$(e.target).parents('.mdui-fab-wrapper').length) {
        _this.close();
      }
    });
  }

  /**
   * �򿪲˵�
   */
  Fab.prototype.open = function () {
    var _this = this;

    if (_this.state === 'opening' || _this.state === 'opened') {
      return;
    }

    // Ϊ�˵��еİ�ť���Ӳ�ͬ�� transition-delay
    _this.$dialBtns.each(function (index, btn) {
      btn.style['transition-delay'] = btn.style['-webkit-transition-delay'] =
        15 * (_this.$dialBtns.length - index) + 'ms';
    });

    _this.$dial
      .css('height', 'auto')
      .addClass('mdui-fab-dial-show');

    // �����ť�д��� .mdui-fab-opened ��ͼ�꣬�����ͼ���л�
    if (_this.$btn.find('.mdui-fab-opened').length) {
      _this.$btn.addClass('mdui-fab-opened');
    }

    _this.state = 'opening';
    componentEvent('open', 'fab', _this, _this.$fab);

    // ��˳��Ϊ���µ�������򿪣�������Ĵ򿪺�ű�ʾ�������
    _this.$dialBtns.eq(0).transitionEnd(function () {
      if (_this.$btn.hasClass('mdui-fab-opened')) {
        _this.state = 'opened';
        componentEvent('opened', 'fab', _this, _this.$fab);
      }
    });
  };

  /**
   * �رղ˵�
   */
  Fab.prototype.close = function () {
    var _this = this;

    if (_this.state === 'closing' || _this.state === 'closed') {
      return;
    }

    // Ϊ�˵��еİ�ť���Ӳ�ͬ�� transition-delay
    _this.$dialBtns.each(function (index, btn) {
      btn.style['transition-delay'] = btn.style['-webkit-transition-delay'] = 15 * index + 'ms';
    });

    _this.$dial.removeClass('mdui-fab-dial-show');
    _this.$btn.removeClass('mdui-fab-opened');
    _this.state = 'closing';
    componentEvent('close', 'fab', _this, _this.$fab);

    // �����������ιرգ����һ���رպ�ű�ʾ�������
    _this.$dialBtns.eq(-1).transitionEnd(function () {
      if (!_this.$btn.hasClass('mdui-fab-opened')) {
        _this.state = 'closed';
        componentEvent('closed', 'fab', _this, _this.$fab);
        _this.$dial.css('height', 0);
      }
    });
  };

  /**
   * �л��˵��Ĵ�״̬
   */
  Fab.prototype.toggle = function () {
    var _this = this;

    if (_this.state === 'opening' || _this.state === 'opened') {
      _this.close();
    } else if (_this.state === 'closing' || _this.state === 'closed') {
      _this.open();
    }
  };

  /**
   * ��ȡ��ǰ�˵�״̬
   * @returns {'opening'|'opened'|'closing'|'closed'}
   */
  Fab.prototype.getState = function () {
    return this.state;
  };

  /**
   * �Զ�������ʽ��ʾ����������ť
   */
  Fab.prototype.show = function () {
    this.$fab.removeClass('mdui-fab-hide');
  };

  /**
   * �Զ�������ʽ���ظ���������ť
   */
  Fab.prototype.hide = function () {
    this.$fab.addClass('mdui-fab-hide');
  };

  return Fab;
})();
