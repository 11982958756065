/**
 * =============================================================================
 * ************   Drawer ������   ************
 * =============================================================================
 *
 * �������豸��Ĭ����ʾ������������ʾ���ֲ�
 * ���ֻ���ƽ���豸��Ĭ�ϲ���ʾ��������ʼ����ʾ���ֲ㣬�Ҹ��ǵ�����
 */

mdui.Drawer = (function () {

  /**
   * Ĭ�ϲ���
   * @type {{}}
   */
  var DEFAULT = {
    // �������豸���Ƿ���ʾ���ֲ㡣�ֻ���ƽ�岻���������Ӱ�죬ʼ�ջ���ʾ���ֲ�
    overlay: false,

    // �Ƿ�������
    swipe: false,
  };

  var isDesktop = function () {
    return $window.width() >= 1024;
  };

  /**
   * ������ʵ��
   * @param selector ѡ������ HTML �ַ����� DOM Ԫ��
   * @param opts
   * @constructor
   */
  function Drawer(selector, opts) {
    var _this = this;

    _this.$drawer = $(selector).eq(0);
    if (!_this.$drawer.length) {
      return;
    }

    var oldInst = _this.$drawer.data('mdui.drawer');
    if (oldInst) {
      return oldInst;
    }

    _this.options = $.extend({}, DEFAULT, (opts || {}));

    _this.overlay = false; // �Ƿ���ʾ�����ֲ�
    _this.position = _this.$drawer.hasClass('mdui-drawer-right') ? 'right' : 'left';

    if (_this.$drawer.hasClass('mdui-drawer-close')) {
      _this.state = 'closed';
    } else if (_this.$drawer.hasClass('mdui-drawer-open')) {
      _this.state = 'opened';
    } else if (isDesktop()) {
      _this.state = 'opened';
    } else {
      _this.state = 'closed';
    }

    // ��������ڴ�С����ʱ
    $window.on('resize', $.throttle(function () {
      // ���ֻ�ƽ���л�������ʱ
      if (isDesktop()) {
        // �����ʾ�����֣�����������
        if (_this.overlay && !_this.options.overlay) {
          $.hideOverlay();
          _this.overlay = false;
          $.unlockScreen();
        }

        // û��ǿ�ƹرգ���״̬Ϊ��״̬
        if (!_this.$drawer.hasClass('mdui-drawer-close')) {
          _this.state = 'opened';
        }
      }

      // �������л����ֻ�ƽ��ʱ������������Ǵ��ŵ���û�����ֲ㣬��رճ�����
      else {
        if (!_this.overlay && _this.state === 'opened') {
          // ����������ǿ�ƴ�״̬����������
          if (_this.$drawer.hasClass('mdui-drawer-open')) {
            $.showOverlay();
            _this.overlay = true;
            $.lockScreen();

            $('.mdui-overlay').one('click', function () {
              _this.close();
            });
          } else {
            _this.state = 'closed';
          }
        }
      }
    }, 100));

    // �󶨹رհ�ť�¼�
    _this.$drawer.find('[mdui-drawer-close]').each(function () {
      $(this).on('click', function () {
        _this.close();
      });
    });

    swipeSupport(_this);
  }

  /**
   * ��������֧��
   * @param _this
   */
  var swipeSupport = function (_this) {
    // �������������ƿ���
    var openNavEventHandler;
    var touchStartX;
    var touchStartY;
    var swipeStartX;
    var swiping = false;
    var maybeSwiping = false;
    var $body = $('body');

    // ���ƴ����ķ�Χ
    var swipeAreaWidth = 24;

    function enableSwipeHandling() {
      if (!openNavEventHandler) {
        $body.on('touchstart', onBodyTouchStart);
        openNavEventHandler = onBodyTouchStart;
      }
    }

    function setPosition(translateX, closeTransform) {
      var rtlTranslateMultiplier = _this.position === 'right' ? -1 : 1;
      var transformCSS = 'translate(' + (-1 * rtlTranslateMultiplier * translateX) + 'px, 0) !important;';
      _this.$drawer.css(
        'cssText',
        'transform:' + transformCSS + (closeTransform ? 'transition: initial !important;' : '')
      );
    }

    function cleanPosition() {
      _this.$drawer.css({
        transform: '',
        transition: '',
      });
    }

    function getMaxTranslateX() {
      return _this.$drawer.width() + 10;
    }

    function getTranslateX(currentX) {
      return Math.min(
        Math.max(
          swiping === 'closing' ? (swipeStartX - currentX) : (getMaxTranslateX() + swipeStartX - currentX),
          0
        ),
        getMaxTranslateX()
      );
    }

    function onBodyTouchStart(event) {
      touchStartX = event.touches[0].pageX;
      if (_this.position === 'right') {
        touchStartX = $body.width() - touchStartX;
      }

      touchStartY = event.touches[0].pageY;

      if (_this.state !== 'opened') {
        if (touchStartX > swipeAreaWidth || openNavEventHandler !== onBodyTouchStart) {
          return;
        }
      }

      maybeSwiping = true;

      $body.on({
        touchmove: onBodyTouchMove,
        touchend: onBodyTouchEnd,
        touchcancel: onBodyTouchMove,
      });
    }

    function onBodyTouchMove(event) {
      var touchX = event.touches[0].pageX;
      if (_this.position === 'right') {
        touchX = $body.width() - touchX;
      }

      var touchY = event.touches[0].pageY;

      if (swiping) {
        setPosition(getTranslateX(touchX), true);
      } else if (maybeSwiping) {
        var dXAbs = Math.abs(touchX - touchStartX);
        var dYAbs = Math.abs(touchY - touchStartY);
        var threshold = 8;

        if (dXAbs > threshold && dYAbs <= threshold) {
          swipeStartX = touchX;
          swiping = _this.state === 'opened' ? 'closing' : 'opening';
          $.lockScreen();
          setPosition(getTranslateX(touchX), true);
        } else if (dXAbs <= threshold && dYAbs > threshold) {
          onBodyTouchEnd();
        }
      }
    }

    function onBodyTouchEnd(event) {
      if (swiping) {
        var touchX = event.changedTouches[0].pageX;
        if (_this.position === 'right') {
          touchX = $body.width() - touchX;
        }

        var translateRatio = getTranslateX(touchX) / getMaxTranslateX();

        maybeSwiping = false;
        var swipingState = swiping;
        swiping = null;

        if (swipingState === 'opening') {
          if (translateRatio < 0.92) {
            cleanPosition();
            _this.open();
          } else {
            cleanPosition();
          }
        } else {
          if (translateRatio > 0.08) {
            cleanPosition();
            _this.close();
          } else {
            cleanPosition();
          }
        }

        $.unlockScreen();
      } else {
        maybeSwiping = false;
      }

      $body.off({
        touchmove: onBodyTouchMove,
        touchend: onBodyTouchEnd,
        touchcancel: onBodyTouchMove,
      });
    }

    if (_this.options.swipe) {
      enableSwipeHandling();
    }
  };

  /**
   * ���������ص�
   * @param inst
   */
  var transitionEnd = function (inst) {
    if (inst.$drawer.hasClass('mdui-drawer-open')) {
      inst.state = 'opened';
      componentEvent('opened', 'drawer', inst, inst.$drawer);
    } else {
      inst.state = 'closed';
      componentEvent('closed', 'drawer', inst, inst.$drawer);
    }
  };

  /**
   * �򿪳�����
   */
  Drawer.prototype.open = function () {
    var _this = this;

    if (_this.state === 'opening' || _this.state === 'opened') {
      return;
    }

    _this.state = 'opening';
    componentEvent('open', 'drawer', _this, _this.$drawer);

    if (!_this.options.overlay) {
      $('body').addClass('mdui-drawer-body-' + _this.position);
    }

    _this.$drawer
      .removeClass('mdui-drawer-close')
      .addClass('mdui-drawer-open')
      .transitionEnd(function () {
        transitionEnd(_this);
      });

    if (!isDesktop() || _this.options.overlay) {
      _this.overlay = true;
      $.showOverlay().one('click', function () {
        _this.close();
      });

      $.lockScreen();
    }
  };

  /**
   * �رճ�����
   */
  Drawer.prototype.close = function () {
    var _this = this;

    if (_this.state === 'closing' || _this.state === 'closed') {
      return;
    }

    _this.state = 'closing';
    componentEvent('close', 'drawer', _this, _this.$drawer);

    if (!_this.options.overlay) {
      $('body').removeClass('mdui-drawer-body-' + _this.position);
    }

    _this.$drawer
      .addClass('mdui-drawer-close')
      .removeClass('mdui-drawer-open')
      .transitionEnd(function () {
        transitionEnd(_this);
      });

    if (_this.overlay) {
      $.hideOverlay();
      _this.overlay = false;
      $.unlockScreen();
    }
  };

  /**
   * �л���������/�ر�״̬
   */
  Drawer.prototype.toggle = function () {
    var _this = this;

    if (_this.state === 'opening' || _this.state === 'opened') {
      _this.close();
    } else if (_this.state === 'closing' || _this.state === 'closed') {
      _this.open();
    }
  };

  /**
   * ��ȡ������״̬
   * @returns {'opening'|'opened'|'closing'|'closed'}
   */
  Drawer.prototype.getState = function () {
    return this.state;
  };

  return Drawer;

})();
