/**
 * =============================================================================
 * ************   Snackbar   ************
 * =============================================================================
 */

(function () {

  /**
   * ��ǰ���ŵ� Snackbar
   */
  var currentInst;

  /**
   * ������
   * @type {string}
   */
  var queueName = '__md_snackbar';

  var DEFAULT = {
    timeout: 4000,                  // ���û�û�в���ʱ�೤ʱ���Զ�����
    buttonText: '',                 // ��ť���ı�
    buttonColor: '',                // ��ť����ɫ��֧�� blue #90caf9 rgba(...)
    position: 'bottom',             // λ�� bottom��top��left-top��left-bottom��right-top��right-bottom
    closeOnButtonClick: true,       // �����ťʱ�ر�
    closeOnOutsideClick: true,      // ����������Ļ�����ط�ʱ�ر�
    onClick: function () {          // �� Snackbar �ϵ���Ļص�
    },

    onButtonClick: function () {    // �����ť�Ļص�
    },

    onOpen: function () {           // �򿪶�����ʼʱ�Ļص�
    },

    onOpened: function () {         // �򿪶�������ʱ�Ļص�
    },

    onClose: function () {          // �رն�����ʼʱ�Ļص�
    },

    onClosed: function () {         // �򿪶�������ʱ�Ļص�
    },
  };

  /**
   * ��� Snackbar ���������ر�
   * @param e
   */
  var closeOnOutsideClick = function (e) {
    var $target = $(e.target);
    if (!$target.hasClass('mdui-snackbar') && !$target.parents('.mdui-snackbar').length) {
      currentInst.close();
    }
  };

  /**
   * Snackbar ʵ��
   * @param message
   * @param opts
   * @constructor
   */
  function Snackbar(message, opts) {
    var _this = this;

    _this.message = message;
    _this.options = $.extend({}, DEFAULT, (opts || {}));

    // message ��������
    if (!_this.message) {
      return;
    }

    _this.state = 'closed';

    _this.timeoutId = false;

    // ��ť��ɫ
    var buttonColorStyle = '';
    var buttonColorClass = '';

    if (
      _this.options.buttonColor.indexOf('#') === 0 ||
      _this.options.buttonColor.indexOf('rgb') === 0
    ) {
      buttonColorStyle = 'style="color:' + _this.options.buttonColor + '"';
    } else if (_this.options.buttonColor !== '') {
      buttonColorClass = 'mdui-text-color-' + _this.options.buttonColor;
    }

    // ���� HTML
    _this.$snackbar = $(
      '<div class="mdui-snackbar">' +
        '<div class="mdui-snackbar-text">' +
          _this.message +
        '</div>' +
        (_this.options.buttonText ?
          ('<a href="javascript:void(0)" ' +
          'class="mdui-snackbar-action mdui-btn mdui-ripple mdui-ripple-white ' +
            buttonColorClass + '" ' +
            buttonColorStyle + '>' +
            _this.options.buttonText +
          '</a>') :
          ''
        ) +
      '</div>')
      .appendTo(document.body);

    // ����λ��
    _this._setPosition('close');

    _this.$snackbar
      .reflow()
      .addClass('mdui-snackbar-' + _this.options.position);
  }

  /**
   * ���� Snackbar ��λ��
   * @param state
   * @private
   */
  Snackbar.prototype._setPosition = function (state) {
    var _this = this;

    var snackbarHeight = _this.$snackbar[0].clientHeight;
    var position = _this.options.position;

    var translateX;
    var translateY;

    // translateX
    if (position === 'bottom' || position === 'top') {
      translateX = '-50%';
    } else {
      translateX = '0';
    }

    // translateY
    if (state === 'open') {
      translateY = '0';
    } else {
      if (position === 'bottom') {
        translateY = snackbarHeight;
      }

      if (position === 'top') {
        translateY = -snackbarHeight;
      }

      if (position === 'left-top' || position === 'right-top') {
        translateY = -snackbarHeight - 24;
      }

      if (position === 'left-bottom' || position === 'right-bottom') {
        translateY = snackbarHeight + 24;
      }
    }

    _this.$snackbar.transform('translate(' + translateX + ',' + translateY + 'px)');
  };

  /**
   * �� Snackbar
   */
  Snackbar.prototype.open = function () {
    var _this = this;

    if (!_this.message) {
      return;
    }

    if (_this.state === 'opening' || _this.state === 'opened') {
      return;
    }

    // �����ǰ��������ʾ�� Snackbar�����ȼ�����У��Ⱦ� Snackbar �رպ��ٴ�
    if (currentInst) {
      queue.queue(queueName, function () {
        _this.open();
      });

      return;
    }

    currentInst = _this;

    // ��ʼ��
    _this.state = 'opening';
    _this.options.onOpen();

    _this._setPosition('open');

    _this.$snackbar
      .transitionEnd(function () {
        if (_this.state !== 'opening') {
          return;
        }

        _this.state = 'opened';
        _this.options.onOpened();

        // �а�ťʱ���¼�
        if (_this.options.buttonText) {
          _this.$snackbar
            .find('.mdui-snackbar-action')
            .on('click', function () {
              _this.options.onButtonClick();
              if (_this.options.closeOnButtonClick) {
                _this.close();
              }
            });
        }

        // ��� snackbar ���¼�
        _this.$snackbar.on('click', function (e) {
          if (!$(e.target).hasClass('mdui-snackbar-action')) {
            _this.options.onClick();
          }
        });

        // ��� Snackbar ���������ر�
        if (_this.options.closeOnOutsideClick) {
          $document.on(TouchHandler.start, closeOnOutsideClick);
        }

        // ��ʱ���Զ��ر�
        if (_this.options.timeout) {
          _this.timeoutId = setTimeout(function () {
            _this.close();
          }, _this.options.timeout);
        }
      });
  };

  /**
   * �ر� Snackbar
   */
  Snackbar.prototype.close = function () {
    var _this = this;

    if (!_this.message) {
      return;
    }

    if (_this.state === 'closing' || _this.state === 'closed') {
      return;
    }

    if (_this.timeoutId) {
      clearTimeout(_this.timeoutId);
    }

    if (_this.options.closeOnOutsideClick) {
      $document.off(TouchHandler.start, closeOnOutsideClick);
    }

    _this.state = 'closing';
    _this.options.onClose();

    _this._setPosition('close');

    _this.$snackbar
      .transitionEnd(function () {
        if (_this.state !== 'closing') {
          return;
        }

        currentInst = null;
        _this.state = 'closed';
        _this.options.onClosed();
        _this.$snackbar.remove();
        queue.dequeue(queueName);
      });
  };

  /**
   * �� Snackbar
   * @param message
   * @param opts
   */
  mdui.snackbar = function (message, opts) {
    if (typeof message !== 'string') {
      opts = message;
      message = opts.message;
    }

    var inst = new Snackbar(message, opts);

    inst.open();
    return inst;
  };

})();
