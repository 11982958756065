/**
 * =============================================================================
 * ************   ToolTip ������ʾ   ************
 * =============================================================================
 */

mdui.Tooltip = (function () {

  /**
   * Ĭ�ϲ���
   */
  var DEFAULT = {
    position: 'auto',     // ��ʾ����λ��
    delay: 0,             // �ӳ٣���λ����
    content: '',          // ��ʾ�ı����������� HTML
  };

  /**
   * �Ƿ��������豸
   * @returns {boolean}
   */
  var isDesktop = function () {
    return $window.width() > 1024;
  };

  /**
   * ���� Tooltip ��λ��
   * @param inst
   */
  function setPosition(inst) {
    var marginLeft;
    var marginTop;
    var position;

    // ������Ԫ��
    var targetProps = inst.$target[0].getBoundingClientRect();

    // ������Ԫ�غ� Tooltip ֮��ľ���
    var targetMargin = (isDesktop() ? 14 : 24);

    // Tooltip �Ŀ��Ⱥ͸߶�
    var tooltipWidth = inst.$tooltip[0].offsetWidth;
    var tooltipHeight = inst.$tooltip[0].offsetHeight;

    // Tooltip �ķ���
    position = inst.options.position;

    // �Զ��ж�λ�ã��� 2px��ʹ Tooltip ���봰�ڱ߿������� 2px �ļ��
    if (['bottom', 'top', 'left', 'right'].indexOf(position) === -1) {
      if (
        targetProps.top + targetProps.height + targetMargin + tooltipHeight + 2 <
        $window.height()
      ) {
        position = 'bottom';
      } else if (targetMargin + tooltipHeight + 2 < targetProps.top) {
        position = 'top';
      } else if (targetMargin + tooltipWidth + 2 < targetProps.left) {
        position = 'left';
      } else if (
        targetProps.width + targetMargin + tooltipWidth + 2 <
        $window.width() - targetProps.left
      ) {
        position = 'right';
      } else {
        position = 'bottom';
      }
    }

    // ����λ��
    switch (position) {
      case 'bottom':
        marginLeft = -1 * (tooltipWidth / 2);
        marginTop = (targetProps.height / 2) + targetMargin;
        inst.$tooltip.transformOrigin('top center');
        break;
      case 'top':
        marginLeft = -1 * (tooltipWidth / 2);
        marginTop = -1 * (tooltipHeight + (targetProps.height / 2) + targetMargin);
        inst.$tooltip.transformOrigin('bottom center');
        break;
      case 'left':
        marginLeft = -1 * (tooltipWidth + (targetProps.width / 2) + targetMargin);
        marginTop = -1 * (tooltipHeight / 2);
        inst.$tooltip.transformOrigin('center right');
        break;
      case 'right':
        marginLeft = (targetProps.width / 2) + targetMargin;
        marginTop = -1 * (tooltipHeight / 2);
        inst.$tooltip.transformOrigin('center left');
        break;
    }

    var targetOffset = inst.$target.offset();
    inst.$tooltip.css({
      top: targetOffset.top + (targetProps.height / 2) + 'px',
      left: targetOffset.left + (targetProps.width / 2) + 'px',
      'margin-left': marginLeft + 'px',
      'margin-top': marginTop + 'px',
    });
  }

  /**
   * Tooltip ʵ��
   * @param selector
   * @param opts
   * @constructor
   */
  function Tooltip(selector, opts) {
    var _this = this;

    _this.$target = $(selector).eq(0);
    if (!_this.$target.length) {
      return;
    }

    // ��ͨ�� data ����ʵ�������������ظ�ʵ����
    var oldInst = _this.$target.data('mdui.tooltip');
    if (oldInst) {
      return oldInst;
    }

    _this.options = $.extend({}, DEFAULT, (opts || {}));
    _this.state = 'closed';

    // ���� Tooltip HTML
    _this.$tooltip = $(
      '<div class="mdui-tooltip" id="' + $.guid() + '">' +
        _this.options.content +
      '</div>'
    ).appendTo(document.body);

    // ���¼���Ԫ�ش��� disabled ״̬ʱ�޷���������¼���Ϊ��ͳһ���� touch �¼�Ҳ����
    _this.$target
      .on('touchstart mouseenter', function (e) {
        if (this.disabled) {
          return;
        }

        if (!TouchHandler.isAllow(e)) {
          return;
        }

        TouchHandler.register(e);

        _this.open();
      })
      .on('touchend mouseleave', function (e) {
        if (this.disabled) {
          return;
        }

        if (!TouchHandler.isAllow(e)) {
          return;
        }

        _this.close();
      })
      .on(TouchHandler.unlock, function (e) {
        if (this.disabled) {
          return;
        }

        TouchHandler.register(e);
      });
  }

  /**
   * ���������ص�
   * @private
   */
  var transitionEnd = function (inst) {
    if (inst.$tooltip.hasClass('mdui-tooltip-open')) {
      inst.state = 'opened';
      componentEvent('opened', 'tooltip', inst, inst.$target);
    } else {
      inst.state = 'closed';
      componentEvent('closed', 'tooltip', inst, inst.$target);
    }
  };

  /**
   * ʵ�д� Tooltip
   * @private
   */
  Tooltip.prototype._doOpen = function () {
    var _this = this;

    _this.state = 'opening';
    componentEvent('open', 'tooltip', _this, _this.$target);

    _this.$tooltip
      .addClass('mdui-tooltip-open')
      .transitionEnd(function () {
        transitionEnd(_this);
      });
  };

  /**
   * �� Tooltip
   * @param opts ����ÿ�δ�ʱ���ò�ͬ�Ĳ���
   */
  Tooltip.prototype.open = function (opts) {
    var _this = this;

    if (_this.state === 'opening' || _this.state === 'opened') {
      return;
    }

    var oldOpts = $.extend({}, _this.options);

    // �ϲ� data ���Բ���
    $.extend(_this.options, parseOptions(_this.$target.attr('mdui-tooltip')));
    if (opts) {
      $.extend(_this.options, opts);
    }

    // tooltip �������и���
    if (oldOpts.content !== _this.options.content) {
      _this.$tooltip.html(_this.options.content);
    }

    setPosition(_this);

    if (_this.options.delay) {
      _this.timeoutId = setTimeout(function () {
        _this._doOpen();
      }, _this.options.delay);
    } else {
      _this.timeoutId = false;
      _this._doOpen();
    }
  };

  /**
   * �ر� Tooltip
   */
  Tooltip.prototype.close = function () {
    var _this = this;

    if (_this.timeoutId) {
      clearTimeout(_this.timeoutId);
      _this.timeoutId = false;
    }

    if (_this.state === 'closing' || _this.state === 'closed') {
      return;
    }

    _this.state = 'closing';
    componentEvent('close', 'tooltip', _this, _this.$target);

    _this.$tooltip
      .removeClass('mdui-tooltip-open')
      .transitionEnd(function () {
        transitionEnd(_this);
      });
  };

  /**
   * �л� Tooltip ״̬
   */
  Tooltip.prototype.toggle = function () {
    var _this = this;

    if (_this.state === 'opening' || _this.state === 'opened') {
      _this.close();
    } else if (_this.state === 'closing' || _this.state === 'closed') {
      _this.open();
    }
  };

  /**
   * ��ȡ Tooltip ״̬
   * @returns {'opening'|'opened'|'closing'|'closed'}
   */
  Tooltip.prototype.getState = function () {
    return this.state;
  };

  /**
   * ���� Tooltip
   */
  /*Tooltip.prototype.destroy = function () {
    var _this = this;
    clearTimeout(_this.timeoutId);
    $.data(_this.target, 'mdui.tooltip', null);
    $.remove(_this.tooltip);
  };*/

  return Tooltip;

})();
