/**
 * =============================================================================
 * ************   mdui.dialog(options)   ************
 * =============================================================================
 */

mdui.dialog = function (options) {

  /**
   * Ĭ�ϲ���
   */
  var DEFAULT = {
    title: '',                // ����
    content: '',              // �ı�
    buttons: [],              // ��ť
    stackedButtons: false,    // ��ֱ���а�ť
    cssClass: '',             // �� Dialog �����ӵ� CSS ��
    history: true,            // ���� hashchange �¼�
    overlay: true,            // �Ƿ���ʾ����
    modal: false,             // �Ƿ�ģ̬���Ի���
    closeOnEsc: true,         // ���� esc ʱ�رնԻ���
    destroyOnClosed: true,    // �رպ�����
    onOpen: function () {     // �򿪶�����ʼʱ�Ļص�
    },

    onOpened: function () {   // �򿪶���������Ļص�
    },

    onClose: function () {    // �رն�����ʼʱ�Ļص�
    },

    onClosed: function () {   // �رն�������ʱ�Ļص�
    },
  };

  /**
   * ��ť��Ĭ�ϲ���
   */
  var DEFAULT_BUTTON = {
    text: '',                   // ��ť�ı�
    bold: false,                // ��ť�ı��Ƿ�Ӵ�
    close: true,                // �����ť��رնԻ���
    onClick: function (inst) {  // �����ť�Ļص�
    },
  };

  // �ϲ�����
  options = $.extend({}, DEFAULT, (options || {}));
  $.each(options.buttons, function (i, button) {
    options.buttons[i] = $.extend({}, DEFAULT_BUTTON, button);
  });

  // ��ť�� HTML
  var buttonsHTML = '';
  if (options.buttons.length) {
    buttonsHTML =
      '<div class="mdui-dialog-actions ' +
        (options.stackedButtons ? 'mdui-dialog-actions-stacked' : '') +
      '">';
    $.each(options.buttons, function (i, button) {
      buttonsHTML +=
        '<a href="javascript:void(0)" ' +
          'class="mdui-btn mdui-ripple mdui-text-color-primary ' +
          (button.bold ? 'mdui-btn-bold' : '') + '">' +
          button.text +
        '</a>';
    });

    buttonsHTML += '</div>';
  }

  // Dialog �� HTML
  var HTML =
    '<div class="mdui-dialog ' + options.cssClass + '">' +
      (options.title ? '<div class="mdui-dialog-title">' + options.title + '</div>' : '') +
      (options.content ? '<div class="mdui-dialog-content">' + options.content + '</div>' : '') +
      buttonsHTML +
    '</div>';

  // ʵ���� Dialog
  var inst = new mdui.Dialog(HTML, {
    history: options.history,
    overlay: options.overlay,
    modal: options.modal,
    closeOnEsc: options.closeOnEsc,
    destroyOnClosed: options.destroyOnClosed,
  });

  // �󶨰�ť�¼�
  if (options.buttons.length) {
    inst.$dialog.find('.mdui-dialog-actions .mdui-btn').each(function (i, button) {
      $(button).on('click', function () {
        if (typeof options.buttons[i].onClick === 'function') {
          options.buttons[i].onClick(inst);
        }

        if (options.buttons[i].close) {
          inst.close();
        }
      });
    });
  }

  // �󶨴򿪹ر��¼�
  if (typeof options.onOpen === 'function') {
    inst.$dialog
      .on('open.mdui.dialog', function () {
        options.onOpen(inst);
      })
      .on('opened.mdui.dialog', function () {
        options.onOpened(inst);
      })
      .on('close.mdui.dialog', function () {
        options.onClose(inst);
      })
      .on('closed.mdui.dialog', function () {
        options.onClosed(inst);
      });
  }

  inst.open();

  return inst;
};
