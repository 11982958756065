/**
 * =============================================================================
 * ************   Headroom.js   ************
 * =============================================================================
 */

mdui.Headroom = (function () {

  /**
   * Ĭ�ϲ���
   * @type {{}}
   */
  var DEFAULT = {
    tolerance: 5,                                 // �������������پ��뿪ʼ���ػ���ʾԪ�أ�{down: num, up: num}��������
    offset: 0,                                    // ��ҳ�涥�����پ����ڹ�����������Ԫ��
    initialClass: 'mdui-headroom',                // ��ʼ��ʱ���ӵ���
    pinnedClass: 'mdui-headroom-pinned-top',      // Ԫ�ع̶�ʱ���ӵ���
    unpinnedClass: 'mdui-headroom-unpinned-top',  // Ԫ������ʱ���ӵ���
  };

  /**
   * Headroom
   * @param selector
   * @param opts
   * @constructor
   */
  function Headroom(selector, opts) {
    var _this = this;

    _this.$headroom = $(selector).eq(0);
    if (!_this.$headroom.length) {
      return;
    }

    // ��ͨ���Զ�������ʵ�������������ظ�ʵ����
    var oldInst = _this.$headroom.data('mdui.headroom');
    if (oldInst) {
      return oldInst;
    }

    _this.options = $.extend({}, DEFAULT, (opts || {}));

    // ��ֵתΪ {down: bum, up: num}
    var tolerance = _this.options.tolerance;
    if (tolerance !== Object(tolerance)) {
      _this.options.tolerance = {
        down: tolerance,
        up: tolerance,
      };
    }

    _this._init();
  }

  /**
   * ��ʼ��
   * @private
   */
  Headroom.prototype._init = function () {
    var _this = this;

    _this.state = 'pinned';
    _this.$headroom
      .addClass(_this.options.initialClass)
      .removeClass(_this.options.pinnedClass + ' ' + _this.options.unpinnedClass);

    _this.inited = false;
    _this.lastScrollY = 0;

    _this._attachEvent();
  };

  /**
   * ���������¼�
   * @private
   */
  Headroom.prototype._attachEvent = function () {
    var _this = this;

    if (!_this.inited) {
      _this.lastScrollY = window.pageYOffset;
      _this.inited = true;

      $window.on('scroll', function () {
        _this._scroll();
      });
    }
  };

  /**
   * ����ʱ�Ĵ���
   * @private
   */
  Headroom.prototype._scroll = function () {
    var _this = this;
    _this.rafId = window.requestAnimationFrame(function () {
      var currentScrollY = window.pageYOffset;
      var direction = currentScrollY > _this.lastScrollY ? 'down' : 'up';
      var toleranceExceeded =
        Math.abs(currentScrollY - _this.lastScrollY) >=
        _this.options.tolerance[direction];

      if (
        currentScrollY > _this.lastScrollY &&
        currentScrollY >= _this.options.offset &&
        toleranceExceeded) {
        _this.unpin();
      } else if (
        (currentScrollY < _this.lastScrollY && toleranceExceeded) ||
        currentScrollY <= _this.options.offset
      ) {
        _this.pin();
      }

      _this.lastScrollY = currentScrollY;
    });
  };

  /**
   * ���������ص�
   * @param inst
   */
  var transitionEnd = function (inst) {
    if (inst.state === 'pinning') {
      inst.state = 'pinned';
      componentEvent('pinned', 'headroom', inst, inst.$headroom);
    }

    if (inst.state === 'unpinning') {
      inst.state = 'unpinned';
      componentEvent('unpinned', 'headroom', inst, inst.$headroom);
    }
  };

  /**
   * �̶�ס
   */
  Headroom.prototype.pin = function () {
    var _this = this;

    if (
      _this.state === 'pinning' ||
      _this.state === 'pinned' ||
      !_this.$headroom.hasClass(_this.options.initialClass)
    ) {
      return;
    }

    componentEvent('pin', 'headroom', _this, _this.$headroom);

    _this.state = 'pinning';

    _this.$headroom
      .removeClass(_this.options.unpinnedClass)
      .addClass(_this.options.pinnedClass)
      .transitionEnd(function () {
        transitionEnd(_this);
      });
  };

  /**
   * ���̶�ס
   */
  Headroom.prototype.unpin = function () {
    var _this = this;

    if (
      _this.state === 'unpinning' ||
      _this.state === 'unpinned' ||
      !_this.$headroom.hasClass(_this.options.initialClass)
    ) {
      return;
    }

    componentEvent('unpin', 'headroom', _this, _this.$headroom);

    _this.state = 'unpinning';

    _this.$headroom
      .removeClass(_this.options.pinnedClass)
      .addClass(_this.options.unpinnedClass)
      .transitionEnd(function () {
        transitionEnd(_this);
      });
  };

  /**
   * ����
   */
  Headroom.prototype.enable = function () {
    var _this = this;

    if (!_this.inited) {
      _this._init();
    }
  };

  /**
   * ����
   */
  Headroom.prototype.disable = function () {
    var _this = this;

    if (_this.inited) {
      _this.inited = false;
      _this.$headroom
        .removeClass([
          _this.options.initialClass,
          _this.options.pinnedClass,
          _this.options.unpinnedClass,
        ].join(' '));

      $window.off('scroll', function () {
        _this._scroll();
      });

      window.cancelAnimationFrame(_this.rafId);
    }
  };

  /**
   * ��ȡ��ǰ״̬ pinning | pinned | unpinning | unpinned
   */
  Headroom.prototype.getState = function () {
    return this.state;
  };

  return Headroom;

})();
