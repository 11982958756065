/**
 * =============================================================================
 * ************   Expansion panel ����չ���   ************
 * =============================================================================
 */

mdui.Panel = (function () {

  function Panel(selector, opts) {
    return new CollapsePrivate(selector, opts, 'panel');
  }

  return Panel;

})();
