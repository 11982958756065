/**
 * =============================================================================
 * ************   Text Field �ı���   ************
 * =============================================================================
 */

(function () {

  var getProp = function (obj, prop) {
    return (
      typeof obj === 'object' &&
      obj !== null &&
      obj[prop] !== undefined &&
      obj[prop]
    ) ? obj[prop] : false;
  };

  /**
   * ������¼�
   * @param e
   */
  var inputEvent = function (e) {
    var input = e.target;
    var $input = $(input);
    var event = e.type;
    var value = $input.val();

    // reInit Ϊ true ʱ����Ҫ���³�ʼ���ı���
    var reInit = getProp(e.detail, 'reInit');

    // domLoadedEvent Ϊ true ʱ��Ϊ DOM ������Ϻ��Զ��������¼�
    var domLoadedEvent = getProp(e.detail, 'domLoadedEvent');

    // �ı�������
    var type = $input.attr('type') || '';
    if (['checkbox', 'button', 'submit', 'range', 'radio', 'image'].indexOf(type) >= 0) {
      return;
    }

    var $textField = $input.parent('.mdui-textfield');

    // ������Ƿ�۽�
    if (event === 'focus') {
      $textField.addClass('mdui-textfield-focus');
    }

    if (event === 'blur') {
      $textField.removeClass('mdui-textfield-focus');
    }

    // ������Ƿ�Ϊ��
    if (event === 'blur' || event === 'input') {
      $textField[(value && value !== '') ? 'addClass' : 'removeClass']('mdui-textfield-not-empty');
    }

    // ������Ƿ����
    $textField[input.disabled ? 'addClass' : 'removeClass']('mdui-textfield-disabled');

    // ������֤
    if ((event === 'input' || event === 'blur') && !domLoadedEvent) {
      if (input.validity) {
        var method = input.validity.valid ? 'removeClass' : 'addClass';
        $textField[method]('mdui-textfield-invalid-html5');
      }
    }

    // textarea �߶��Զ�����
    if (e.target.nodeName.toLowerCase() === 'textarea') {

      // IE bug��textarea ��ֵ��Ϊ������У�������������ʱ��textarea �ĸ߶Ȳ�׼ȷ
      //         ��ʱ���ڼ���߶�ǰ����ֵ�Ŀ�ͷ����һ���ո񣬼�������Ƴ��ո�
      var inputValue = $input.val();
      var hasExtraSpace = false;
      if (inputValue.replace(/[\r\n]/g, '') === '') {
        $input.val(' ' + inputValue);
        hasExtraSpace = true;
      }

      // ���� textarea �߶�
      $input.height('');
      var height = $input.height();
      var scrollHeight = input.scrollHeight;

      if (scrollHeight > height) {
        $input.height(scrollHeight);
      }

      // �����꣬��ԭ textarea ��ֵ
      if (hasExtraSpace) {
        $input.val(inputValue);
      }
    }

    // ʵʱ����ͳ��
    if (reInit) {
      $textField
        .find('.mdui-textfield-counter')
        .remove();
    }

    var maxlength = $input.attr('maxlength');
    if (maxlength) {
      if (reInit || domLoadedEvent) {
        $('<div class="mdui-textfield-counter">' +
            '<span class="mdui-textfield-counter-inputed"></span> / ' + maxlength +
          '</div>').appendTo($textField);
      }

      $textField.find('.mdui-textfield-counter-inputed').text(value.length.toString());
    }

    // �� �����ı���������ʾ������ͳ�� ʱ�������ı���ײ��ڱ߾�
    if (
      $textField.find('.mdui-textfield-helper').length ||
      $textField.find('.mdui-textfield-error').length ||
      maxlength
    ) {
      $textField.addClass('mdui-textfield-has-bottom');
    }
  };

  // ���¼�
  $document.on('input focus blur', '.mdui-textfield-input', { useCapture: true }, inputEvent);

  // ��չ���ı���չ��
  $document.on('click', '.mdui-textfield-expandable .mdui-textfield-icon', function () {
    $(this)

      // չ���ı���
      .parents('.mdui-textfield')
      .addClass('mdui-textfield-expanded')

      // �۽��������
      .find('.mdui-textfield-input')[0].focus();
  });

  // ��չ���ı���ر�
  $document.on('click', '.mdui-textfield-expanded .mdui-textfield-close', function () {
    $(this)

      // �ر��ı���
      .parents('.mdui-textfield')
      .removeClass('mdui-textfield-expanded')

      // ��������
      .find('.mdui-textfield-input')
      .val('');
  });

  /**
   * ͨ�� JS �����˱������ݣ���Ҫ���½��б�������
   * @param- ��������� .mdui-textfield ���ڵ� DOM Ԫ�أ�����¸��ı��򣻷��򣬸��������ı���
   */
  mdui.updateTextFields = function () {
    $(arguments.length ? arguments[0] : '.mdui-textfield').each(function () {
      $(this)
        .find('.mdui-textfield-input')
        .trigger('input', {
          reInit: true,
        });
    });
  };
})();

$(function () {
  /**
   * ��ʼ���ı���
   */
  mdui.mutation('.mdui-textfield', function () {
    $(this)
      .find('.mdui-textfield-input')
      .trigger('input', {
        domLoadedEvent: true,
      });
  });
});
