/**
 * =============================================================================
 * ************   ����   ************
 * =============================================================================
 *
 * Inspired by https://github.com/nolimits4web/Framework7/blob/master/src/js/fast-clicks.js
 * https://github.com/nolimits4web/Framework7/blob/master/LICENSE
 *
 * Inspired by https://github.com/fians/Waves
 */

(function () {

  var Ripple = {

    /**
     * ��ʱ��������ָ����ʱҲ������������λ�����룩
     */
    delay: 200,

    /**
     * ��ʾ��������
     * @param e
     * @param $ripple
     */
    show: function (e, $ripple) {

      // ����Ҽ�����������
      if (e.button === 2) {
        return;
      }

      // ���λ������
      var tmp;
      if ('touches' in e && e.touches.length) {
        tmp = e.touches[0];
      } else {
        tmp = e;
      }

      var touchStartX = tmp.pageX;
      var touchStartY = tmp.pageY;

      // ����λ��
      var offset = $ripple.offset();
      var center = {
        x: touchStartX - offset.left,
        y: touchStartY - offset.top,
      };

      var height = $ripple.innerHeight();
      var width = $ripple.innerWidth();
      var diameter = Math.max(
        Math.pow((Math.pow(height, 2) + Math.pow(width, 2)), 0.5), 48
      );

      // ������ɢ����
      var translate =
        'translate3d(' + (-center.x + width / 2) + 'px, ' + (-center.y + height / 2) + 'px, 0) ' +
        'scale(1)';

      // ������ DOM �ṹ
      $('<div class="mdui-ripple-wave" style="' +
        'width: ' + diameter + 'px; ' +
        'height: ' + diameter + 'px; ' +
        'margin-top:-' + diameter / 2 + 'px; ' +
        'margin-left:-' + diameter / 2 + 'px; ' +
        'left:' + center.x + 'px; ' +
        'top:' + center.y + 'px;">' +
        '</div>')

        // ���涯��Ч��
        .data('translate', translate)

        .prependTo($ripple)
        .reflow()
        .transform(translate);
    },

    /**
     * ������������
     */
    hide: function (e, element) {
      var $ripple = $(element || this);

      $ripple.children('.mdui-ripple-wave').each(function () {
        removeRipple($(this));
      });

      $ripple.off('touchmove touchend touchcancel mousemove mouseup mouseleave', Ripple.hide);
    },
  };

  /**
   * ���ز��Ƴ�����
   * @param $wave
   */
  function removeRipple($wave) {
    if (!$wave.length || $wave.data('isRemoved')) {
      return;
    }

    $wave.data('isRemoved', true);

    var removeTimeout = setTimeout(function () {
      $wave.remove();
    }, 400);

    var translate = $wave.data('translate');

    $wave
      .addClass('mdui-ripple-wave-fill')
      .transform(translate.replace('scale(1)', 'scale(1.01)'))
      .transitionEnd(function () {
        clearTimeout(removeTimeout);

        $wave
          .addClass('mdui-ripple-wave-out')
          .transform(translate.replace('scale(1)', 'scale(1.01)'));

        removeTimeout = setTimeout(function () {
          $wave.remove();
        }, 700);

        setTimeout(function () {
          $wave.transitionEnd(function () {
            clearTimeout(removeTimeout);
            $wave.remove();
          });
        }, 0);
      });
  }

  /**
   * ��ʾ���������� touchend ���¼�
   * @param e
   */
  function showRipple(e) {
    if (!TouchHandler.isAllow(e)) {
      return;
    }

    TouchHandler.register(e);

    // Chrome 59 ���������ʱ������ document �ϴ����¼�
    if (e.target === document) {
      return;
    }

    var $ripple;
    var $target = $(e.target);

    // ��ȡ�� .mdui-ripple ���Ԫ��
    if ($target.hasClass('mdui-ripple')) {
      $ripple = $target;
    } else {
      $ripple = $target.parents('.mdui-ripple').eq(0);
    }

    if ($ripple.length) {

      // ����״̬��Ԫ���ϲ���������Ч��
      if ($ripple[0].disabled || $ripple.attr('disabled') !== null) {
        return;
      }

      if (e.type === 'touchstart') {
        var hidden = false;

        // toucstart ����ָ��ʱ���ʼ��������
        var timer = setTimeout(function () {
          timer = null;
          Ripple.show(e, $ripple);
        }, Ripple.delay);

        var hideRipple = function (hideEvent) {
          // �����ָû���ƶ���������������û�п�ʼ����ʼ��������
          if (timer) {
            clearTimeout(timer);
            timer = null;
            Ripple.show(e, $ripple);
          }

          if (!hidden) {
            hidden = true;
            Ripple.hide(hideEvent, $ripple);
          }
        };

        // ��ָ�ƶ����Ƴ���������
        var touchMove = function (moveEvent) {
          if (timer) {
            clearTimeout(timer);
            timer = null;
          }

          hideRipple(moveEvent);
        };

        $ripple
          .on('touchmove', touchMove)
          .on('touchend touchcancel', hideRipple);

      } else {
        Ripple.show(e, $ripple);
        $ripple.on('touchmove touchend touchcancel mousemove mouseup mouseleave', Ripple.hide);
      }
    }
  }

  // ��ʼ���󶨵��¼�
  $document
    .on(TouchHandler.start, showRipple)
    .on(TouchHandler.unlock, TouchHandler.register);
})();
