/**
 * =============================================================================
 * ************   mdui.prompt(label, title, onConfirm, onCancel, options)   ************
 * ************   mdui.prompt(label, onConfirm, onCancel, options)          ************
 * =============================================================================
 */

mdui.prompt = function (label, title, onConfirm, onCancel, options) {

  // title ������ѡ
  if (typeof title === 'function') {
    title = '';
    onConfirm = arguments[1];
    onCancel = arguments[2];
    options = arguments[3];
  }

  if (onConfirm === undefined) {
    onConfirm = function () {};
  }

  if (onCancel === undefined) {
    onCancel = function () {};
  }

  if (options === undefined) {
    options = {};
  }

  /**
   * Ĭ�ϲ���
   */
  var DEFAULT = {
    confirmText: 'ok',        // ȷ�ϰ�ť���ı�
    cancelText: 'cancel',     // ȡ����ť���ı�
    history: true,            // ���� hashchange �¼�
    modal: false,             // �Ƿ�ģ̬���Ի���Ϊ false ʱ����Ի�����������رնԻ���Ϊ true ʱ���ر�
    closeOnEsc: true,         // ���� esc �رնԻ���
    type: 'text',             // ��������ͣ�text: �����ı��� textarea: �����ı���
    maxlength: '',            // ��������ַ���
    defaultValue: '',         // ������е�Ĭ���ı�
    confirmOnEnter: false,    // ���� enter ȷ����������
  };

  options = $.extend({}, DEFAULT, options);

  var content =
    '<div class="mdui-textfield">' +
      (label ? '<label class="mdui-textfield-label">' + label + '</label>' : '') +
      (options.type === 'text' ?
        '<input class="mdui-textfield-input" type="text" ' +
          'value="' + options.defaultValue + '" ' +
          (options.maxlength ? ('maxlength="' + options.maxlength + '"') : '') + '/>' :
        '') +
      (options.type === 'textarea' ?
        '<textarea class="mdui-textfield-input" ' +
          (options.maxlength ? ('maxlength="' + options.maxlength + '"') : '') + '>' +
            options.defaultValue +
        '</textarea>' :
        '') +
    '</div>';

  var onCancelClick = onCancel;
  if (typeof onCancel === 'function') {
    onCancelClick = function (inst) {
      var value = inst.$dialog.find('.mdui-textfield-input').val();
      onCancel(value, inst);
    }
  }

  var onConfirmClick = onConfirm;
  if (typeof onConfirm === 'function') {
    onConfirmClick = function (inst) {
      var value = inst.$dialog.find('.mdui-textfield-input').val();
      onConfirm(value, inst);
    }
  }

  return mdui.dialog({
    title: title,
    content: content,
    buttons: [
      {
        text: options.cancelText,
        bold: false,
        close: true,
        onClick: onCancelClick,
      },
      {
        text: options.confirmText,
        bold: false,
        close: true,
        onClick: onConfirmClick,
      },
    ],
    cssClass: 'mdui-dialog-prompt',
    history: options.history,
    modal: options.modal,
    closeOnEsc: options.closeOnEsc,
    onOpen: function (inst) {

      // ��ʼ�������
      var $input = inst.$dialog.find('.mdui-textfield-input');
      mdui.updateTextFields($input);

      // �۽��������
      $input[0].focus();

      // ��׽�ı���س������ڵ����ı��������´����ص�
      if (options.type === 'text' && options.confirmOnEnter === true) {
        $input.on('keydown', function (event) {
          if (event.keyCode === 13) {
            var value = inst.$dialog.find('.mdui-textfield-input').val();
            onConfirm(value, inst);
            inst.close();
          }
        });
      }

      // ����Ƕ�������򣬼��������� input �¼������¶Ի���߶�
      if (options.type === 'textarea') {
        $input.on('input', function () {
          inst.handleUpdate();
        });
      }

      // ���ַ�������ʱ���������ı���� DOM ��仯����Ҫ���¶Ի���߶�
      if (options.maxlength) {
        inst.handleUpdate();
      }
    },
  });

};
