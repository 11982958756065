/**
 * =============================================================================
 * ************   MDUI �ڲ�ʹ�õĺ���   ************
 * =============================================================================
 */

/**
 * ���� DATA API �Ĳ���
 * @param str
 * @returns {*}
 */
var parseOptions = function (str) {
  var options = {};

  if (str === null || !str) {
    return options;
  }

  if (typeof str === 'object') {
    return str;
  }

  /* jshint ignore:start */
  var start = str.indexOf('{');
  try {
    options = (new Function('',
      'var json = ' + str.substr(start) +
      '; return JSON.parse(JSON.stringify(json));'))();
  } catch (e) {
  }
  /* jshint ignore:end */

  return options;
};

/**
 * ��������¼�
 * @param eventName �¼���
 * @param pluginName �����
 * @param inst ���ʵ��
 * @param trigger �ڸ�Ԫ���ϴ���
 * @param obj �¼�����
 */
var componentEvent = function (eventName, pluginName, inst, trigger, obj) {
  if (!obj) {
    obj = {};
  }

  obj.inst = inst;

  var fullEventName = eventName + '.mdui.' + pluginName;

  // jQuery �¼�
  if (typeof jQuery !== 'undefined') {
    jQuery(trigger).trigger(fullEventName, obj);
  }

  // JQ �¼�
  $(trigger).trigger(fullEventName, obj);
};
