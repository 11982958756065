/**
 * =============================================================================
 * ************   Dialog �Ի���   ************
 * =============================================================================
 */

mdui.Dialog = (function () {

  /**
   * Ĭ�ϲ���
   */
  var DEFAULT = {
    history: true,                // ���� hashchange �¼�
    overlay: true,                // �򿪶Ի���ʱ�Ƿ���ʾ����
    modal: false,                 // �Ƿ�ģ̬���Ի���Ϊ false ʱ����Ի�����������رնԻ���Ϊ true ʱ���ر�
    closeOnEsc: true,             // ���� esc �رնԻ���
    closeOnCancel: true,          // ����ȡ����ťʱ�رնԻ���
    closeOnConfirm: true,         // ����ȷ�ϰ�ťʱ�رնԻ���
    destroyOnClosed: false,        // �رպ�����
  };

  /**
   * ���ֲ�Ԫ��
   */
  var $overlay;

  /**
   * �����Ƿ�������
   */
  var isLockScreen;

  /**
   * ��ǰ�Ի���ʵ��
   */
  var currentInst;

  /**
   * ������
   */
  var queueName = '__md_dialog';

  /**
   * ���ڿ��ȱ仯����Ի������ݱ仯ʱ�������Ի���λ�úͶԻ����ڵĹ�����
   */
  var readjust = function () {
    if (!currentInst) {
      return;
    }

    var $dialog = currentInst.$dialog;

    var $dialogTitle = $dialog.children('.mdui-dialog-title');
    var $dialogContent = $dialog.children('.mdui-dialog-content');
    var $dialogActions = $dialog.children('.mdui-dialog-actions');

    // ���� dialog �� top �� height ֵ
    $dialog.height('');
    $dialogContent.height('');

    var dialogHeight = $dialog.height();
    $dialog.css({
      top: (($window.height() - dialogHeight) / 2) + 'px',
      height: dialogHeight + 'px',
    });

    // ���� mdui-dialog-content �ĸ߶�
    $dialogContent.height(dialogHeight - ($dialogTitle.height() || 0) - ($dialogActions.height() || 0));
  };

  /**
   * hashchange �¼�����ʱ�رնԻ���
   */
  var hashchangeEvent = function () {
    if (location.hash.substring(1).indexOf('mdui-dialog') < 0) {
      currentInst.close(true);
    }
  };

  /**
   * ������ֲ�رնԻ���
   * @param e
   */
  var overlayClick = function (e) {
    if ($(e.target).hasClass('mdui-overlay') && currentInst) {
      currentInst.close();
    }
  };

  /**
   * �Ի���ʵ��
   * @param selector ѡ������ HTML �ַ����� DOM Ԫ��
   * @param opts
   * @constructor
   */
  function Dialog(selector, opts) {
    var _this = this;

    // �Ի���Ԫ��
    _this.$dialog = $(selector).eq(0);
    if (!_this.$dialog.length) {
      return;
    }

    // ��ͨ�� data ����ʵ�������������ظ�ʵ����
    var oldInst = _this.$dialog.data('mdui.dialog');
    if (oldInst) {
      return oldInst;
    }

    // ����Ի���Ԫ��û���ڵ�ǰ�ĵ��У�����Ҫ����
    if (!$.contains(document.body, _this.$dialog[0])) {
      _this.append = true;
      $('body').append(_this.$dialog);
    }

    _this.options = $.extend({}, DEFAULT, (opts || {}));
    _this.state = 'closed';

    // ��ȡ����ť�¼�
    _this.$dialog.find('[mdui-dialog-cancel]').each(function () {
      $(this).on('click', function () {
        componentEvent('cancel', 'dialog', _this, _this.$dialog);
        if (_this.options.closeOnCancel) {
          _this.close();
        }
      });
    });

    // ��ȷ�ϰ�ť�¼�
    _this.$dialog.find('[mdui-dialog-confirm]').each(function () {
      $(this).on('click', function () {
        componentEvent('confirm', 'dialog', _this, _this.$dialog);
        if (_this.options.closeOnConfirm) {
          _this.close();
        }
      });
    });

    // �󶨹رհ�ť�¼�
    _this.$dialog.find('[mdui-dialog-close]').each(function () {
      $(this).on('click', function () {
        _this.close();
      });
    });
  }

  /**
   * ���������ص�
   * @param inst
   */
  var transitionEnd = function (inst) {
    if (inst.$dialog.hasClass('mdui-dialog-open')) {
      inst.state = 'opened';
      componentEvent('opened', 'dialog', inst, inst.$dialog);
    } else {
      inst.state = 'closed';
      componentEvent('closed', 'dialog', inst, inst.$dialog);

      inst.$dialog.hide();

      // ���жԻ��򶼹رգ��ҵ�ǰû�д򿪵ĶԻ���ʱ��������Ļ
      if (queue.queue(queueName).length === 0 && !currentInst && isLockScreen) {
        $.unlockScreen();
        isLockScreen = false;
      }

      $window.off('resize', $.throttle(function () {
        readjust();
      }, 100));

      if (inst.options.destroyOnClosed) {
        inst.destroy();
      }
    }
  };

  /**
   * ��ָ���Ի���
   * @private
   */
  Dialog.prototype._doOpen = function () {
    var _this = this;

    currentInst = _this;

    if (!isLockScreen) {
      $.lockScreen();
      isLockScreen = true;
    }

    _this.$dialog.show();

    readjust();
    $window.on('resize', $.throttle(function () {
      readjust();
    }, 100));

    // ����Ϣ��
    _this.state = 'opening';
    componentEvent('open', 'dialog', _this, _this.$dialog);

    _this.$dialog
      .addClass('mdui-dialog-open')
      .transitionEnd(function () {
        transitionEnd(_this);
      });

    // ���������ֲ�Ԫ��ʱ���������ֲ�
    if (!$overlay) {
      $overlay = $.showOverlay(5100);
    }

    $overlay

      // ������ֲ�ʱ�Ƿ�رնԻ���
      [_this.options.modal ? 'off' : 'on']('click', overlayClick)

      // �Ƿ���ʾ���ֲ㣬����ʾʱ�������ֲ㱳��͸��
      .css('opacity', _this.options.overlay ? '' : 0);

    if (_this.options.history) {
      // ��� hash ��ԭ������ mdui-dialog����ɾ�������������ʷ��¼����Ȼ�� mdui-dialog �����޷��ر�
      // ���� mdui-dialog �� &mdui-dialog �� ?mdui-dialog
      var hash = location.hash.substring(1);
      if (hash.indexOf('mdui-dialog') > -1) {
        hash = hash.replace(/[&?]?mdui-dialog/g, '');
      }

      // ���˰�ť�رնԻ���
      if (hash) {
        location.hash = hash + (hash.indexOf('?') > -1 ? '&' : '?') + 'mdui-dialog';
      } else {
        location.hash = 'mdui-dialog';
      }

      $window.on('hashchange', hashchangeEvent);
    }
  };

  /**
   * �򿪶Ի���
   */
  Dialog.prototype.open = function () {
    var _this = this;

    if (_this.state === 'opening' || _this.state === 'opened') {
      return;
    }

    // �����ǰ�����ڴ򿪻��Ѿ��򿪵ĶԻ���,����в�Ϊ�գ����ȼ�����У��ȾɶԻ���ʼ�ر�ʱ�ٴ�
    if (
      (currentInst && (currentInst.state === 'opening' || currentInst.state === 'opened')) ||
      queue.queue(queueName).length
    ) {
      queue.queue(queueName, function () {
        _this._doOpen();
      });

      return;
    }

    _this._doOpen();
  };

  /**
   * �رնԻ���
   */
  Dialog.prototype.close = function () {
    var _this = this;
    var _arguments = arguments;

    // setTimeout �������ǣ�
    // ��ͬʱ�ر�һ���Ի��򣬲�����һ���Ի���ʱ��ʹ�򿪶Ի���Ĳ�����ʵ�У���ʹ��Ҫ�򿪵ĶԻ����ȼ������
    setTimeout(function () {
      if (_this.state === 'closing' || _this.state === 'closed') {
        return;
      }

      currentInst = null;

      _this.state = 'closing';
      componentEvent('close', 'dialog', _this, _this.$dialog);

      // ���жԻ��򶼹رգ��ҵ�ǰû�д򿪵ĶԻ���ʱ����������
      if (queue.queue(queueName).length === 0 && $overlay) {
        $.hideOverlay();
        $overlay = null;
      }

      _this.$dialog
        .removeClass('mdui-dialog-open')
        .transitionEnd(function () {
          transitionEnd(_this);
        });

      if (_this.options.history && queue.queue(queueName).length === 0) {
        // �Ƿ���Ҫ������ʷ��¼��Ĭ��Ϊ false��
        // Ϊ false ʱ��ͨ�� js �رգ���Ҫ����һ����ʷ��¼
        // Ϊ true ʱ��ͨ�����˰�ť�رգ�����Ҫ������ʷ��¼
        if (!_arguments[0]) {
          window.history.back();
        }

        $window.off('hashchange', hashchangeEvent);
      }

      // �رվɶԻ��򣬴��¶Ի���
      // ��һ���ӳ٣�����Ϊ���Ӿ�Ч�����á�������ʱҲ��Ӱ�칦��
      setTimeout(function () {
        queue.dequeue(queueName);
      }, 100);
    }, 0);
  };

  /**
   * �л��Ի����/�ر�״̬
   */
  Dialog.prototype.toggle = function () {
    var _this = this;

    if (_this.state === 'opening' || _this.state === 'opened') {
      _this.close();
    } else if (_this.state === 'closing' || _this.state === 'closed') {
      _this.open();
    }
  };

  /**
   * ��ȡ�Ի���״̬
   * @returns {'opening'|'opened'|'closing'|'closed'}
   */
  Dialog.prototype.getState = function () {
    return this.state;
  };

  /**
   * ���ٶԻ���
   */
  Dialog.prototype.destroy = function () {
    var _this = this;

    if (_this.append) {
      _this.$dialog.remove();
    }

    _this.$dialog.removeData('mdui.dialog');

    if (queue.queue(queueName).length === 0 && !currentInst) {
      if ($overlay) {
        $.hideOverlay();
        $overlay = null;
      }

      if (isLockScreen) {
        $.unlockScreen();
        isLockScreen = false;
      }
    }
  };

  /**
   * �Ի������ݱ仯ʱ����Ҫ���ø÷����������Ի���λ�ú͹������߶�
   */
  Dialog.prototype.handleUpdate = function () {
    readjust();
  };

  // esc ����ʱ�رնԻ���
  $document.on('keydown', function (e) {
    if (
      currentInst &&
      currentInst.options.closeOnEsc &&
      currentInst.state === 'opened' &&
      e.keyCode === 27
    ) {
      currentInst.close();
    }
  });

  return Dialog;

})();
