/**
 * =============================================================================
 * ************   mdui.confirm(text, title, onConfirm, onCancel, options)   ************
 * ************   mdui.confirm(text, onConfirm, onCancel, options)          ************
 * =============================================================================
 */

mdui.confirm = function (text, title, onConfirm, onCancel, options) {

  // title ������ѡ
  if (typeof title === 'function') {
    title = '';
    onConfirm = arguments[1];
    onCancel = arguments[2];
    options = arguments[3];
  }

  if (onConfirm === undefined) {
    onConfirm = function () {};
  }

  if (onCancel === undefined) {
    onCancel = function () {};
  }

  if (options === undefined) {
    options = {};
  }

  /**
   * Ĭ�ϲ���
   */
  var DEFAULT = {
    confirmText: 'ok',            // ȷ�ϰ�ť���ı�
    cancelText: 'cancel',         // ȡ����ť���ı�
    history: true,                // ���� hashchange �¼�
    modal: false,                 // �Ƿ�ģ̬���Ի���Ϊ false ʱ����Ի�����������رնԻ���Ϊ true ʱ���ر�
    closeOnEsc: true,             // ���� esc �رնԻ���
  };

  options = $.extend({}, DEFAULT, options);

  return mdui.dialog({
    title: title,
    content: text,
    buttons: [
      {
        text: options.cancelText,
        bold: false,
        close: true,
        onClick: onCancel,
      },
      {
        text: options.confirmText,
        bold: false,
        close: true,
        onClick: onConfirm,
      },
    ],
    cssClass: 'mdui-dialog-confirm',
    history: options.history,
    modal: options.modal,
    closeOnEsc: options.closeOnEsc,
  });
};
